import React, { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { ChartWrapper } from "./chartWrapper";
import { DownloadsChart } from "./downloadsChart";
import { CHART_OPTIONS, IS_TAB_SCREEN } from "../../../../configs";
import "./index.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Chart = React.memo(
  ({
    cardHeading,
    chartHeight,
    graphData,
    tab,
    hideFilters,
    isActiveUsersChart,
    handleChangeFilters,
    handleTracksToggle,
  }) => {
    useEffect(() => {
      ChartJS.defaults.borderColor = "#ffffff0f";
      ChartJS.defaults.scale.grid.borderColor = "rgb(255 255 255 / 0%)";
    });

    const isMetricsChart = useMemo(() => {
      return ["download_data", "user_data", "session_data"].includes(
        tab?.metricsType
      );
    }, [tab?.metricsType]);

    const isDownloadsChart = useMemo(() => {
      return tab?.metricsType === "download_data";
    }, [tab?.metricsType]);

    const classes = useMemo(() => {
      if (isDownloadsChart) return "downloads_chart metrics-chart-wrapper";
      if (isMetricsChart) return "metrics-chart-wrapper";
      if (cardHeading === "Recognition") return "recognition-chart";
      return "";
    }, [cardHeading, isDownloadsChart, isMetricsChart]);

    const getMaxRotation = () => {
      const isSmScreen = window.innerWidth < 800;
      if (isActiveUsersChart && !isSmScreen) return 0;
      return 50;
    };

    return (
      <Box className="card-wrapper">
        <ChartWrapper
          cardHeading={cardHeading}
          hideFilters={hideFilters}
          tab={tab}
          graphData={graphData}
          isDownloadsChart={isDownloadsChart}
          handleChangeFilters={handleChangeFilters}
          handleTracksToggle={handleTracksToggle}
        >
          <Box
            className={`chart-wrapper ${classes}`}
            style={{ height: IS_TAB_SCREEN ? "unset" : chartHeight }}
          >
            {isDownloadsChart ? (
              <DownloadsChart
                chartHeight={chartHeight}
                graphData={graphData}
                cardHeading={cardHeading}
              />
            ) : (
              <Line
                height={chartHeight}
                data={{
                  radius: 0,
                  labels: graphData?.labels,
                  datasets: [
                    {
                      label: cardHeading,
                      data: graphData?.data,
                      borderColor: ["#ff4a4a"],
                      backgroundColor: ["rgba(0, 0, 0, 0.1)"],
                      pointHoverBackgroundColor: "rgba(255, 26, 26, 1)",
                      pointHoverBorderColor: "rgba(255,255,255,1)",
                      pointHoverRadius: 5,
                      pointHoverBorderWidth: 3,
                    },
                  ],
                }}
                options={{
                  ...CHART_OPTIONS,
                  scales: {
                    ...CHART_OPTIONS.scales,
                    x: {
                      ...CHART_OPTIONS.scales.x,
                      ticks: {
                        ...CHART_OPTIONS.scales.x.ticks,
                        autoSkip: isActiveUsersChart ? true : false,
                        maxTicksLimit: 8,
                        maxRotation: getMaxRotation(),
                      },
                    },
                  },
                }}
              />
            )}
          </Box>
        </ChartWrapper>
      </Box>
    );
  }
);
