import React, { useMemo, memo } from "react";
import { Box, Tooltip } from "@mui/material";

export const CustomTooltip = memo(
  ({
    label,
    data,
    index,
    tableName,
    openTooltip,
    isShowComplete,
    selectedAttribute,
    handleCloseTooltip,
    showAttributeDetails,
  }) => {
    const check = useMemo(() => {
      return (
        openTooltip[selectedAttribute] &&
        `${data}-${tableName}-${index}` === selectedAttribute[0]
      );
    }, [data, index, openTooltip, selectedAttribute, tableName]);

    const handleClickTooltip = (e) => {
      e.stopPropagation();

      const isDisplayMessage = true;

      handleCloseTooltip(data, isDisplayMessage);

      if (tableName.includes("account-type")) {
        return;
      }

      navigator.clipboard.writeText(
        selectedAttribute && selectedAttribute[0]?.split("-")[0]
      );
    };

    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleCloseTooltip}
        open={check ? check : false}
        disableFocusListener
        disableTouchListener
        placement="top"
        title={
          <Box
            className={`show-attributes ${
              isShowComplete ? "attributes-spacing" : ""
            }`}
            onClick={handleClickTooltip}
          >
            {isShowComplete ? (
              <p>
                {(selectedAttribute &&
                  selectedAttribute[0]?.split(`-${tableName}`)[0]) ||
                  "N/A"}
              </p>
            ) : (
              <p>{selectedAttribute && selectedAttribute[0]?.split("-")[0]}</p>
            )}
          </Box>
        }
      >
        <Box
          className="tooltip-element"
          onMouseEnter={() =>
            showAttributeDetails(data, `${tableName}-${index}`)
          }
          onClick={() => showAttributeDetails(data, `${tableName}-${index}`)}
        >
          {label}
        </Box>
      </Tooltip>
    );
  }
);
