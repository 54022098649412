import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { getAuth } from "firebase/auth";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Box, Container, Snackbar } from "@mui/material";
import { useInterval } from "../../hooks";
import { useAuthenticationStore, useUserStore } from "../../store";
import { UsersTable, VerificationModal } from "./components";
import {
  userSearch,
  updateAccountType,
  useUserDashboardData,
} from "../../apis";
import {
  GRID_COLUMNS,
  GRID_BREAKPOINTS,
  INITIAL_VERIFICATION,
  DATA_ERRORS,
  ANALYTICS_REFETCH_INTERVAL,
  USERS_TABLE,
  IS_XXL_SCREEN,
  IS_MD_SCREEN,
  IS_MOBILE_SCREEN,
  IS_TAB_SCREEN,
} from "../../configs";
import { addCountryNamesToData, getTimeOflastUpdate } from "../../utils";
import { useAuth } from "../../hooks";

const ResponsiveGridLayout = WidthProvider(Responsive);

const IS_FILTER_OPTIONS =
  localStorage.getItem("user_filter_options") &&
  JSON.parse(localStorage.getItem("user_filter_options"));

export const UsersDashboard = () => {
  const auth = getAuth();

  const { handleStartLoading, handleStopLoading, onLogout } = useAuth();
  const { userIdToken, userData } = useAuthenticationStore();

  const {
    usersDashboardData,
    layoutLg,
    maxHeight,
    lastUpdateTime,
    setUsersDashboardData,
    setLayoutLg,
    setMaxHeight,
    setLastUpdateTime,
  } = useUserStore();

  const [filters, setFilters] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(
    IS_FILTER_OPTIONS || []
  );
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [isVerify, setIsVerify] = useState(INITIAL_VERIFICATION);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [accountType, setAccountType] = useState(30);
  const [page, setPage] = useState({ currentPage: 0, prevPage: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("join_date");
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const controllerRef = useRef(null);
  const tableRef = useRef(null);
  const isFirstRender = useRef(true);
  const layoutRef = useRef(null);

  const analyticsParams = useMemo(() => {
    const params = {
      user_id: localStorage.getItem("user_id"),
      access_token: userIdToken,
      sort: orderBy,
      order: order === "desc" ? -1 : 1,
      skip: rowsPerPage * page.currentPage,
      per_page: rowsPerPage,
      filters: selectedFilterOptions.map((s) => s.key),
    };
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userIdToken,
    selectedFilterOptions,
    page?.currentPage,
    order,
    orderBy,
    rowsPerPage,
  ]);

  const dashboardData = useUserDashboardData(analyticsParams, isFetch);

  const dashboard_data = dashboardData && dashboardData.data;
  const response_status = dashboardData && dashboardData.responseStatus;

  useEffect(() => {
    if (!usersDashboardData) {
      // handleStartLoading();
      setIsLoadingSearch(true);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUpdateData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!usersDashboardData || isUpdateData) {
      const chk =
        analyticsParams?.access_token &&
        analyticsParams?.per_page > 0 &&
        isUpdateData;
      setIsFetch(chk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analyticsParams?.access_token,
    analyticsParams?.per_page,
    usersDashboardData,
    isUpdateData,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setIsLoadingSearch(true);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analyticsParams?.order,
    analyticsParams?.sort,
    analyticsParams?.skip,
    search,
  ]);

  useEffect(() => {
    if (!search) {
      setIsLoadingSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (dashboardData && dashboardData.responseStatus !== 200) {
      getLastUpdateTime();

      if (DATA_ERRORS.includes(dashboardData?.status) && !auth?.currentUser)
        logout();
      return;
    }

    if (dashboardData && dashboardData.responseStatus === 200) fetchAnalytics();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_data, response_status]);

  useEffect(() => {
    async function calculateHeights() {
      const offsetTopGap =
        IS_MOBILE_SCREEN || IS_XXL_SCREEN ? 80 : IS_TAB_SCREEN ? 44 : 53;
      const tablePaddingTop = IS_MOBILE_SCREEN ? 8 : 14;
      const tableHeaderHeight = IS_MOBILE_SCREEN ? 50 : 51;
      const footerHeight = 74;
      const tableBottomGap = 40;
      const rowHeight = 47;
      const { offsetTop } = tableRef.current;

      const tableBodyHeight =
        window.innerHeight -
        offsetTop -
        offsetTopGap -
        tableHeaderHeight -
        tablePaddingTop -
        footerHeight -
        tableBottomGap;

      const rows = parseInt(tableBodyHeight / rowHeight);

      setTimeout(() => {
        if (layoutRef?.current?.elementRef?.current?.clientHeight) {
          const availableHeight =
            layoutRef?.current?.elementRef?.current?.clientHeight -
            offsetTop -
            footerHeight -
            tablePaddingTop;

          setMaxHeight(availableHeight);
        }
      }, 3000);

      if (rows <= 0) {
        setRowsPerPage(3);
        handleUpdateLayout(200);
        return;
      }

      setRowsPerPage(rows);
      handleUpdateLayout(tableBodyHeight);
    }

    calculateHeights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef]);

  useEffect(() => {
    async function handleVisibilityChange() {
      if (!document.hidden) {
        const diff = getDifferenceOfUpdate();
        if (diff > ANALYTICS_REFETCH_INTERVAL) await dashboardData?.mutate();
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateTime]);

  useInterval(() => {
    const diff = getDifferenceOfUpdate();
    setIsUpdateData(diff > ANALYTICS_REFETCH_INTERVAL);
  }, ANALYTICS_REFETCH_INTERVAL);

  const fetchAnalytics = useCallback(() => {
    // handleStopLoading();
    setIsLoadingSearch(false);
    getLastUpdateTime();
    const filtersData = getFilters(dashboardData.data.filters);
    setFilters(filtersData);

    if (!search) {
      const updatedData = addCountryNamesToData(
        dashboardData.data?.results,
        "country_active"
      );
      dashboardData.data["results"] = updatedData;
      setUsersDashboardData(dashboardData.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const getFilters = (data) => {
    const structureFilters = Object.entries(data)
      .filter((d) => d[0] !== "all")
      .map(([key, values]) => ({
        key,
        name: key.replace(/_/i, " "),
        values: values.map((v) => ({
          key: v,
          name: v.replace(/_/i, " "),
        })),
      }));
    return structureFilters;
  };

  const isSelected = useCallback(
    (userId) => {
      return selectedUsers?.indexOf(userId) !== -1;
    },
    [selectedUsers]
  );

  const getLastUpdateTime = () => {
    const updateTime = getTimeOflastUpdate();
    setLastUpdateTime(updateTime);
  };

  const logout = async () => {
    await onLogout();
    setIsLoadingSearch(false);
  };

  const getDifferenceOfUpdate = () => {
    return new Date().getTime() - new Date(lastUpdateTime).getTime();
  };

  const showAttributeDetails = (values, index) => {
    let newArr = [];
    if (typeof values === "object") {
      newArr = Object.keys(values).map(function (key) {
        return values[key];
      });
    } else {
      setOpenTooltip({ [`${values}-${index}`]: true });
      newArr = [`${values}-${index}`];
    }
    setSelectedAttribute(newArr);
  };

  const handleCloseTooltip = (id, isDisplayMessage) => {
    setOpenTooltip({ [id]: false });
    if (isDisplayMessage) setOpen(true);
  };

  const handleSelectUsers = (e, userId) => {
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0)
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    setSelectedUsers(newSelected);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    if (!e.target.value) {
      if (controllerRef.current) controllerRef.current.abort();
      setPage({ ...page, currentPage: page.prevPage });
      setUsersDashboardData(dashboardData?.data);
      setIsLoadingSearch(false);
      return;
    }
    setPage({ ...page, currentPage: 0 });
    handleSearch(e.target.value, false);
  };

  const handleSearch = (value) => {
    if (controllerRef.current) controllerRef.current.abort();
    const controller = new AbortController();
    controllerRef.current = controller;
    const searchData = {
      user_id: userData?.user_id,
      access_token: userIdToken,
      query: value,
      per_page: rowsPerPage,
      skip: rowsPerPage * page.currentPage,
    };
    handleUserSearch(searchData, controllerRef.current);
  };

  const handleUserSearch = async (searchData, ref) => {
    const { data, responseStatus, status } = await userSearch(
      searchData,
      ref?.signal
    );
    ref = null;
    if (responseStatus === 200) {
      setError(null);
      setUsersDashboardData(data);
      setIsLoadingSearch(false);
    } else setError(status);
    if (responseStatus === 210) setIsLoadingSearch(false);
  };

  const clearSearch = () => {
    setSearch("");
    setUsersDashboardData(dashboardData?.data);
  };

  const handleOpenModal = () => {
    setIsVerify(INITIAL_VERIFICATION);
    setOpenModal(true);
  };

  const handleChangeAccountType = (e) => setAccountType(Number(e.target.value));

  const handleVerifyUsers = async () => {
    setIsVerify({ ...isVerify, isLoading: true });

    const verifyUsersData = {
      user_id: userData?.user_id,
      access_token: userIdToken,
      user_ids: selectedUsers,
      account_type: accountType,
    };

    const { status, responseStatus } = await updateAccountType(verifyUsersData);

    if (responseStatus === 200) {
      await dashboardData.mutate();
      setError(null);
      setSelectedUsers([]);
      setIsVerify({ ...isVerify, isLoading: false, isSuccess: true });
      setTimeout(closeModal, 1000);
      search && setSearch("");
    } else {
      setError(status);
      closeModal();
    }
  };

  const closeModal = () => setOpenModal(false);

  const handleSelectFilter = (value) => setSelectedFilter(value);

  const handleSelectFilterOptions = (value) => {
    const index = selectedFilterOptions.findIndex((fo) => fo.key === value.key);

    if (index > -1) {
      const newArr = selectedFilterOptions;
      newArr.splice(index, 1);
      localStorage.setItem("user_filter_options", JSON.stringify([...newArr]));
      setSelectedFilterOptions([...newArr]);
      return;
    }
    localStorage.setItem(
      "user_filter_options",
      JSON.stringify([...selectedFilterOptions, value])
    );
    setSelectedFilterOptions([...selectedFilterOptions, value]);
  };

  const handleBackButtonClick = () => {
    setPage({
      ...page,
      currentPage: page.currentPage - 1,
      prevPage: page.prevPage - 1,
    });
  };

  const handleNextButtonClick = () => {
    setPage({
      ...page,
      currentPage: page.currentPage + 1,
      prevPage: page.prevPage + 1,
    });
  };

  const handleRequestSort = (e, property) => {
    if (orderBy !== property) {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUpdateLayout = (tableHeight) => {
    const currLayout = layoutLg;
    const additionalHeight = IS_MOBILE_SCREEN
      ? 335
      : IS_TAB_SCREEN
      ? 245
      : IS_MD_SCREEN
      ? 235
      : 230;

    const layout = calculateLayoutHeight(
      currLayout,
      additionalHeight,
      tableHeight
    );
    setLayoutLg(layout);
  };

  const calculateLayoutHeight = (data, additionalHeight, tableHeight) => {
    const layout = data.map((l) => l);
    const a = (tableHeight + additionalHeight) / 15.75;
    layout[0] = { ...layout[0], h: Number((a / 10).toFixed(2)) };
    return layout;
  };

  return (
    <Box>
      <Box
        className="dashboard-wrapper"
        sx={{
          paddingBottom: { xs: "0px", lg: "10px" },
          paddingTop: { lg: 4.5 },
          paddingLeft: { xl: 5.3, lg: 4.5 },
        }}
      >
        <Box className="last-update-time">Updated last on {lastUpdateTime}</Box>

        <Container maxWidth="xl" sx={{ padding: "0px !important" }}>
          <Box className="static-layout-wrapper">
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: layoutLg, md: layoutLg, xs: layoutLg }}
              breakpoints={GRID_BREAKPOINTS}
              cols={GRID_COLUMNS}
              isDraggable={false}
              isResizable={false}
              ref={layoutRef}
            >
              <Box key={USERS_TABLE}>
                {/* <UsersTable
                  tableRef={tableRef}
                  maxHeight={maxHeight}
                  search={search}
                  users={usersDashboardData?.results}
                  filters={filters}
                  openTooltip={openTooltip}
                  selectedAttribute={selectedAttribute}
                  isDisabled={selectedUsers?.length <= 0}
                  isLoading={isLoadingSearch}
                  page={page.currentPage}
                  rowsPerPage={rowsPerPage}
                  dataLength={usersDashboardData?.total_results}
                  isSelected={isSelected}
                  onClickOpenModal={handleOpenModal}
                  showAttributeDetails={showAttributeDetails}
                  onChangeSearch={handleChangeSearch}
                  handleCloseTooltip={handleCloseTooltip}
                  handleSelectUsers={handleSelectUsers}
                  clearSearch={clearSearch}
                  selectedFilter={selectedFilter}
                  selectedFilterOptions={selectedFilterOptions}
                  onSelectFilters={handleSelectFilter}
                  onSelectFilterOptions={handleSelectFilterOptions}
                  handleBackButtonClick={handleBackButtonClick}
                  handleNextButtonClick={handleNextButtonClick}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                /> */}
              </Box>
            </ResponsiveGridLayout>
          </Box>
        </Container>
      </Box>
{/* 
      <VerificationModal
        open={openModal}
        accountType={accountType}
        isVerify={isVerify}
        onClose={() => setOpenModal(false)}
        onChangeAccountType={handleChangeAccountType}
        handleVerifyUsers={handleVerifyUsers}
      /> */}

      <Box className="message-alert">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
          open={open || error}
          onClose={() => {
            if (open) setOpen(false);
            if (error) setError(null);
          }}
          message={error ? error : open ? "Copied!!!" : ""}
        />
      </Box>
    </Box>
  );
};
