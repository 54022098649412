import React, { memo } from "react";
import { Box, TableRow, TableCell, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getDateFormat } from "../../../../utils";
import { CustomTooltip } from "../../../../components";

import ArrowRightIcon from "../../../../assets/svgs/arrow-right-icon.svg";
import Battery0BarIcon from "../../../../assets/svgs/battery-icons/Battery 0 bar.svg";
import Battery1BarIcon from "../../../../assets/svgs/battery-icons/Battery 1 bar.svg";
import Battery2BarIcon from "../../../../assets/svgs/battery-icons/Battery 2 bar.svg";
import Battery3BarIcon from "../../../../assets/svgs/battery-icons/Battery 3 bar.svg";
import Battery4BarIcon from "../../../../assets/svgs/battery-icons/Battery 4 bar.svg";
import Battery5BarIcon from "../../../../assets/svgs/battery-icons/Battery 5 bar.svg";
import Battery6BarIcon from "../../../../assets/svgs/battery-icons/Battery 6 bar.svg";
import BatteryChargeFullIcon from "../../../../assets/svgs/battery-icons/Battery charging full.svg";
import BatteryFullIcon from "../../../../assets/svgs/battery-icons/Battery full.svg";

import { VMS_TABLE_HEADINGS_WIDTHS } from "../../../../configs/constants/tables";

import {
  getDateAndTimeWithSeconds,
  getTimeWithSeconds,
} from "../../../../utils";

export const VmsRow = memo(({ venue }) => {
  const navigate = useNavigate();

  return (
    <TableRow
      className="analytics-table-row vms-row"
      onClick={() => navigate(`/dashboard/vms-venue/${venue.venue_id}`)}
    >

      <TableCell>
        <Box sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.id }}>
          {!venue.venue_id || venue.venue_id === "undefined" ? "-" : venue.venue_id}
        </Box>
      </TableCell>

      <TableCell>
        <Box sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.name }}>
          {!venue.name || venue.name === "undefined" ? "-" : venue.name}
        </Box>
      </TableCell>

      <TableCell>
        <Box sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.location }}>
          {!venue.location || venue.location === "undefined"
            ? "--"
            : venue.location}
        </Box>
      </TableCell>

    <TableCell>
      <Box sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.battery_percentage, display: "flex", alignItems: "center" }}>
        {(!venue.battery_percentage && venue.battery_percentage !== 0) ||
           venue.battery_percentage === "undefined" ||
           venue.battery_percentage === -1 ? (
            "-"
        ) : (
          <>
            <img
              src={
                venue.power_connected === true ? BatteryChargeFullIcon :
                venue.battery_percentage > 95  ? BatteryFullIcon :
                venue.battery_percentage > 80  ? Battery6BarIcon :
                venue.battery_percentage > 60  ? Battery5BarIcon :
                venue.battery_percentage > 45  ? Battery4BarIcon :
                venue.battery_percentage > 35  ? Battery3BarIcon :
                venue.battery_percentage > 25  ? Battery2BarIcon :
                venue.battery_percentage > 5   ? Battery1BarIcon :
                Battery0BarIcon
              }
              width="18" height="18" 
              // Add padding to the right of the battery icon
              style={{ paddingRight: "3px" }}
            />

            {`${venue.battery_percentage}%`}
          </>
        )}
      </Box>
    </TableCell>

      <TableCell>
        <Box sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.up_time }}>
          {( !venue.up_time && venue.up_time !== 0) || venue.up_time === "undefined"
            ? "--"
            : `${venue.up_time * 100}%`}
        </Box>
      </TableCell>

      <TableCell
        sx={{
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            width: {
              sm: VMS_TABLE_HEADINGS_WIDTHS.status_history,
              display: "flex",
              alignItems: "center",
              gap: "4px",
            },
          }}
        >
          {venue.status_history
            ?.slice(0)
            .reverse()
            .map((status, index) => {
              const maxKey = Object.entries(status.status).reduce((a, b) =>
                parseFloat(b[1]) > parseFloat(a[1]) ? b : a
              )[0];
              return (
                <Box key={`${venue.venue_id}-${status.date}-${index}`}>
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    placement="top-start"
                    classes={{ tooltip: "country-name-tooltip" }}
                    title={
                      <Box className="show-attributes">
                        <p style={{ fontWeight: "500" }}>{getDateFormat(status.date)}</p>
                        {Object.entries(status.status).map(([k, v]) => (
                          <Box key={`${venue.venue_id}-${status.date}-${k}-attribute`}>
                            <p >{k}: {parseInt(v * 100)}%</p>
                          </Box>
                        ))}
                      </Box>
                    }
                  >
                    <Box className={`status-bar`}>
                      {Object.entries(status.status).map(([k, v], index) => (
                        <Box
                          key={`${venue.venue_id}-${status.date}-${k}`}
                          className={`status-${k}`}
                          style={{ height: `${v * 100}%` }}
                        />
                      ))}
                    </Box>
                  </Tooltip>
                </Box>
              );
            })}
        </Box>
      </TableCell>

      <TableCell>
        <Box className="vms-status" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.status }}>
          <span className={`status-${venue.status}`} />

          {
            venue.status === "active" ? "Active" : 
            venue.status === "inactive" ? "Inactive" :
            venue.status === "power-save-mode" ? "Power save mode" : 
            venue.status === "offline" ? "Offline" : 
            venue.status === "no-data" ? "No data" : 
            venue.status
          }
        </Box>
      </TableCell>

      <TableCell>
        <Box className="vms-status" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.last_recognition_attempt }}>
          {!venue.last_recognition_attempt || venue.last_recognition_attempt === "undefined"
            ? "-"
            : getDateAndTimeWithSeconds(venue.last_recognition_attempt, true)}
          </Box>
        
      </TableCell>

      <TableCell style={{ textAlign: "end" }}>
        <Box sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.arrowRight }}>
          <img src={ArrowRightIcon} alt="" className="vms-arrow-right" />
        </Box>
      </TableCell>
    </TableRow>
  );
});
