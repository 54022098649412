export const USER = {
  API: "/dashboard/admin/getUser",
};

export const USER_SEARCH = {
  API: "/dashboard/admin/userSearch",
};

export const ANALYTICS_DATA = {
  API: "/dashboard/admin/getDashboardData",
};

export const UPDATE_ACCOUNT_TYPE = {
  API: "/dashboard/admin/updateAccountType",
};

export const USER_DASHBOARD_DATA = {
  API: "/dashboard/admin/getUserDashboardData",
};

export const VMS_DASHBOARD_DATA = {
  API: "/dashboard/getVMSDashboardData",
};

export const VMS_VENUE_DASHBOARD_DATA = {
  API: "/dashboard/getVMSVenueDashboardData",
};
