import { IS_MOBILE_SCREEN } from "./others";

export const RECENT_ACCOUNTS_HEADINGS = [
  { name: "Date (UTC)", width: "110px" },
  { name: "Username", width: "93px" },
  { name: "Type", width: "26px" },
  { name: "Name", width: "100px" },
  { name: "Country", width: "60px" },
  { name: "Email ID", width: "" },
];

export const REPORTS_TABLE_HEADINGS = [
  { name: "Date (UTC)", width: "140px" },
  { name: "Reporter", width: "93px" },
  { name: "Type", width: "34px" },
  { name: "Reasons", width: "140px" },
  { name: "Attributes", width: "" },
];

export const USERS_TABLE_HEADINGS = [
  { id: "", name: "", width: "30px", isSort: false },
  { id: "join_date", name: "Joined (UTC)", width: "140px", isSort: true },
  { id: "username", name: "Username", width: "150px", isSort: true },
  { id: "name", name: "Name", width: "150px", isSort: true },
  { id: "country_active", name: "Country", width: "50px", isSort: true },
  { id: "", name: "", width: "50px", isSort: false },
  { id: "email", name: "Email ID", width: "220px", isSort: true },
  { id: "", name: "Login", width: "50px", isSort: false },
  { id: "last_active", name: "Last Active (UTC)", width: "", isSort: true },
];

export const VMS_TABLE_HEADINGS_WIDTHS = {
  id: { xs: "80px", sm: "165px" },
  name: { xs: "80px", sm: "120px" },
  location: { xs: "80px", sm: "80px" },
  battery_percentage: { xs: "50px", sm: "50px" },
  up_time: { xs: "50px", sm: "40px" },
  status_history: { xs: "220px", sm: "200px" },
  status: { xs: "80px", sm: "120px" },
  last_recognition_attempt: { xs: "80px", sm: "120px" },
};

export const VMS_TABLE_HEADINGS = [
  { id: "venue_id", name: "Venue ID", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.id.xs : VMS_TABLE_HEADINGS_WIDTHS.id.sm, isSort: true },
  { id: "name", name: "Venue", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.name.xs : VMS_TABLE_HEADINGS_WIDTHS.name.sm, isSort: true },
  { id: "location", name: "Location", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.location.xs : VMS_TABLE_HEADINGS_WIDTHS.location.sm, isSort: true },
  { id: "battery_percentage", name: "Battery", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.battery_percentage.xs : VMS_TABLE_HEADINGS_WIDTHS.battery_percentage.sm, isSort: true },
  { id: "up_time", name: "Up time", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.up_time.xs : VMS_TABLE_HEADINGS_WIDTHS.up_time.sm, isSort: false },
  { id: "status_history", name: "Status", width: VMS_TABLE_HEADINGS_WIDTHS.status_history.xs, isSort: false },
  { id: "status", name: "Status", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.status.xs : VMS_TABLE_HEADINGS_WIDTHS.status.sm, isSort: true },
  { id: "last_recognition_attempt", name: "Last Recognition", width: IS_MOBILE_SCREEN ? VMS_TABLE_HEADINGS_WIDTHS.last_recognition_attempt.xs : VMS_TABLE_HEADINGS_WIDTHS.last_recognition_attempt.sm, isSort: true },
  { id: "", name: "", width: "50px", isSort: false },
];


export const VENUE_TABLE_HEADINGS = [
  { id: "time_recorded", name: "Time Recorded", width: "200px", isSort: true },
  { id: "time_recognised", name: "Time recognised", width: "150px", isSort: true },
  { id: "title", name: "Title", width: "150px", isSort: true },
  { id: "artist", name: "Artist", width: "50px", isSort: true },
  { id: "source", name: "Source", width: "220px", isSort: true },
  { id: "recording_length", name: "Length", width: "220px", isSort: true },
];
