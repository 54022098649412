import React from "react";
import { InputAdornment, TextField } from "@mui/material";

import SearchIcon from "../../assets/svgs/search-icon.svg";
import CloseIcon from "../../assets/svgs/close-icon.svg";
import "./index.css";

export const SearchInput = ({ value, error, onChange, clearSearch }) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder="Search"
      className={`table-search-input ${error ? "search-input-error" : ""}`}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            className={`${value ? "search-icon-filled" : "empty-input"}`}
          >
            <img
              src={CloseIcon}
              alt=""
              className="input-close-icon"
              onClick={clearSearch}
            />
            <img src={SearchIcon} alt="" className="input-search-icon" />
          </InputAdornment>
        ),
      }}
    />
  );
};
