import OrganiserIcon from "../../assets/svgs/organiser-icon.svg";
import MapPinIcon from "../../assets/svgs/map-pin-icon.svg";
import VerifyIcon from "../../assets/svgs/verify-icon.svg";
import { HomeIcon, VmsIcon, UsersIcon } from "../../assets/svgIcons";

export const ANALYTICS_REFETCH_INTERVAL = 300000; // 5 minutes
export const TOKEN_REFRESH_INTERVAL = 3300000; // 55 minutes as token expires after 1 hour
export const MIN_DATE_RANGE = "01/02/2022";
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const IS_XXL_SCREEN =
  typeof window !== "undefined" && window.innerWidth > 1920;

export const IS_MD_SCREEN =
  typeof window !== "undefined" && window.innerWidth < 1200;

export const IS_TAB_SCREEN =
  typeof window !== "undefined" && window.innerWidth < 900;

export const IS_MOBILE_SCREEN =
  typeof window !== "undefined" && window.innerWidth < 600;

export const TRACKS_FILTERS = [
  { filter: "today", filterName: "Today" },
  { filter: "this_month", filterName: "Month" },
  { filter: "this_year", filterName: "Year" },
];

export const ACCOUNTS_TYPES = [
  {
    id: "#001",
    name: "Event Organiser",
    value: 30,
    image: OrganiserIcon,
  },
  {
    id: "#002",
    name: "Venue",
    value: 20,
    image: MapPinIcon,
  },
  {
    id: "#003",
    name: "Verify",
    value: 10,
    image: VerifyIcon,
  },
  {
    id: "#004",
    name: "Unverify",
    value: 0,
    image: "",
  },
];

export const MENU_LIST = [
  { name: "Dashboard", icon: <HomeIcon />, path: "/" },
  { name: "Users", icon: <UsersIcon />, path: "/dashboard/users" },
  { name: "VMS", icon: <VmsIcon />, path: "/dashboard/vms" },
];

export const DATA_ERRORS = [
  "User ID not provided",
  "Access token not provided",
  "Invalid request authentication",
  "Server error: An error occurred while collecting the list of admin users",
  "User is not permitted to access admin content",
  "User not found",
];
