import React, { useMemo } from "react";
import {
  Box,
  IconButton,
  TableFooter,
  TableRow,
  TableCell,
} from "@mui/material";

import ArrowLeftIcon from "../../assets/svgs/arrow-left-icon.svg";
import ArrowRightIcon from "../../assets/svgs/arrow-right-icon.svg";
import "./index.css";

export const CustomTableFooter = ({
  name,
  page,
  dataLength,
  rowsPerPage,
  onClickBackButton,
  onClickNextButton,
}) => {
  const showRecordFrom = useMemo(() => {
    let updatedPage = page + 1;
    const cal = (updatedPage - 1) * rowsPerPage;
    return cal + 1;
  }, [page, rowsPerPage]);

  const showRecordTo = useMemo(() => {
    const numberOfPages = Math.ceil(dataLength / rowsPerPage);
    const updatedPage = page + 1;
    const to =
      numberOfPages === updatedPage ? dataLength : rowsPerPage * updatedPage;
    return to;
  }, [dataLength, page, rowsPerPage]);

  return (
    <TableFooter className="table-footer-wrapper">
      <TableRow>
        <TableCell colSpan={12} sx={{ border: 0, padding: 0 }}>
          <Box className="table-footer-content">
            <p>
              {showRecordFrom}-{showRecordTo} of {dataLength}
            </p>
            <Box>
              <IconButton
                onClick={onClickBackButton}
                disabled={page === 0}
                aria-label="previous page"
                name={name}
              >
                <img src={ArrowLeftIcon} alt="" />
              </IconButton>
              <IconButton
                onClick={onClickNextButton}
                disabled={page >= Math.ceil(dataLength / rowsPerPage) - 1}
                aria-label="next page"
                name={name}
              >
                <img src={ArrowRightIcon} alt="" />
              </IconButton>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
