import { React } from "react";
import Fit from "react-fit";
import Calendar from "react-calendar";
import { Box } from "@mui/material";

import { CustomButton } from "../Button";
import { MIN_DATE_RANGE } from "../../configs";
import { getCalendarDate } from "../../utils";
import ArrowNextIcon from "../../assets/svgs/arrow-next-icon.svg";
import ArrowDownIcon from "../../assets/svgs/arrow-up-outlined-icon.svg";
import "./index.css";

export const CustomCalendar = ({
  calendarRef,
  dateRange,
  onChangeDateRange,
  onToggleCalendar,
  showCalendar,
  onClickSearch,
}) => {
  return (
    <Box className="date-picker-wrapper">
      <Box
        className="dashboard-date-picker"
        sx={{ position: "unset !important" }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
          onClick={onToggleCalendar}
        >
          <Box className="date-wrapper">
            <p>{getCalendarDate(dateRange && dateRange[0])}</p>
            <img src={ArrowNextIcon} alt="" />
            <p>{getCalendarDate(dateRange && dateRange[1])}</p>
          </Box>
          <img
            src={ArrowDownIcon}
            alt=""
            id="calender-icon"
            className={showCalendar ? "calendar-arrow-icon" : "rotate-icon"}
          />
        </Box>
        {showCalendar && (
          <Fit>
            <Box className="calendar-custom-wrapper" ref={calendarRef}>
              <Calendar
                selectRange
                clearIcon={null}
                maxDate={new Date()}
                minDate={new Date(MIN_DATE_RANGE)}
                onChange={onChangeDateRange}
                value={dateRange}
                closeCalendar={false}
                formatShortWeekday={(locale, date) =>
                  ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()]
                }
              />
              <CustomButton
                variant="outlined"
                className="search-calendar-btn"
                onClick={onClickSearch}
              >
                Search
              </CustomButton>
            </Box>
          </Fit>
        )}
      </Box>
    </Box>
  );
};
