/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import {
  VMSDashboard,
  VmsVenueDashboard,
} from "./pages";

import { PrivateRoutes, PublicRoutes } from "./layouts";

const App = () => {
  // eslint-disable-next-line no-unused-vars

  return (
    <div>
      <Routes>
          {/* <Route element={<PrivateRoutes />}> */}

          <Route path="/" element={<VMSDashboard />} />

          <Route
            path="/dashboard/vms-venue/:venueId"
            element={<VmsVenueDashboard />}
          />
        {/* </Route> */}

      </Routes>
    </div>
  );
};

export default App;
