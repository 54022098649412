import { IS_MOBILE_SCREEN, IS_MD_SCREEN } from "./others";
import UserIcon from "../../assets/svgs/user-icon.svg";
import SessionIcon from "../../assets/svgs/session-icon.svg";
import DownloadIcon from "../../assets/svgs/download-icon.svg";

export const INITIAL_VERIFICATION = {
  isLoading: false,
  isSuccess: false,
};

export const INITIAL_METRICS_TAB = {
  tabType: "days",
  metricsType: "download_data",
};

export const INITIAL_RECOGNITION_TAB = {
  tabType: "days",
  metricsType: "recognition_attempts",
};

export const INITIAL_PAGES = {
  accountsPage: 0,
  reportsPage: 0,
  tracksPage: 0,
  prevAccountsPage: 0,
  prevReportsPage: 0,
  prevTracksPage: 0,
};

export const INITIAL_ROWS_PER_PAGE = {
  accountsRows: IS_MOBILE_SCREEN ? 7 : IS_MD_SCREEN ? 9 : 9,
  reportsRows: IS_MOBILE_SCREEN ? 7 : IS_MD_SCREEN ? 9 : 19,
  tracksRows: 4,
};

// to display metrics tabs (users/sessions/downloads) while fetching data from API
export const TOTAL_METRICS_COUNT = {
  data: [],
  labels: [],
  totalCount: [
    {
      title: "Total Downloads",
      image: DownloadIcon,
      metricsType: "download_data",
      count: 0,
      trend: {},
    },
    {
      title: "Total Users",
      image: UserIcon,
      metricsType: "user_data",
      count: 0,
      trend: {},
    },
    {
      title: "Total Sessions",
      image: SessionIcon,
      metricsType: "session_data",
      count: 0,
      trend: {},
    },
  ],
};
