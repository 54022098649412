import { styled } from "@mui/material/styles";
import { SvgIcon } from "@mui/material";

export const StyledSvgIcon = styled((props) => <SvgIcon {...props} />)(
  ({ theme, ...props }) => ({
    height: props.height,
    width: props.width,
    color: props.color,
  })
);
