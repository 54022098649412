import useSWRImmutable from "swr";

import { VMS_DASHBOARD_DATA, ANALYTICS_REFETCH_INTERVAL } from "../configs";
import { fetcher } from "../utils";

export const useVMSDashboardData = (payload) => {
  // console.log("useVMSDashboardDataGet - start");
  // console.log("payload: ", payload);

  const { data, mutate } = useSWRImmutable(

    payload.per_page > 0 ? [VMS_DASHBOARD_DATA.API, payload] : null,
    fetcher,
    {
      refreshInterval: ANALYTICS_REFETCH_INTERVAL,
      revalidateOnFocus: false,
    }
  );
  try {
    
    if (data) {
      // console.log("useVMSDashboardData - data");
      return {
        data: data.data.data,
        status: data.data.status,
        responseStatus: data.status,
        mutate: mutate,
      };
    }
    else {
      // console.log("useVMSDashboardData - no data");
    }
  } catch (err) {
    // console.log("useVMSDashboardData - error");
    return {
      data: null,
      status: null,
      responseStatus: null,
      mutate: mutate,
    };
  }
};
