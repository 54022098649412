import { combine } from "zustand/middleware";
import { create } from "zustand";

const getDefaultValues = () => ({
  userData: null,
  userIdToken: null,
  userId: localStorage.getItem("user_id") || null,
  isLoading: true,
  firebaseUser: null,
  refreshToken: null,
});

export const useAuthenticationStore = create(
  combine(getDefaultValues(), (set) => ({
    setUserData: (userData) => set({ userData }),

    setUserIdToken: (userIdToken) => set({ userIdToken }),

    setUserId: (userId) => set({ userId }),

    setIsLoading: (isLoading) => set({ isLoading }),

    setFirebaseUser: (firebaseUser) => set({ firebaseUser }),

    setRefreshToken: (refreshToken) => set({ refreshToken }),
  }))
);
