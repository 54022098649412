import { StyledSvgIcon } from "./styles";

export const CheckedIcon = () => {
  return (
    <StyledSvgIcon
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#E0E0E0" />
      <path
        d="M15.6364 6L7.63636 14L4 10.3636"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgIcon>
  );
};
