import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  Avatar,
  MenuItem,
  useScrollTrigger,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { Modal } from "../Modal";
import { CustomButton } from "../Button";
import Logo from "../../assets/pngs/Logo.png";
import "./index.css";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export const Header = ({ name, image, background, justifyContent }, props) => {
  const userId = localStorage.getItem("user_id");
  const auth = getAuth();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("user_id");
        navigate("/");
      })
      .catch((err) => console.log(err));

    setOpenModal(false);
  };

  return (
    <>
      <ElevationScroll {...props}>
        <Box
          className={`admin-header-wrapper ${justifyContent}`}
          style={{ background }}
        >
          <Box className="admin-logo-wrapper">
            <img
              src={Logo}
              alt="vollou"
              onClick={() => (window.location.href = "https://vollou.com/")}
            />
          </Box>

          {userId && (
            <Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                <Box className="header-avatar-wrapper">
                  <Avatar alt="" src={image} className="header-avatar" />
                  <p className="header-username">{name}</p>
                </Box>
                <CustomButton
                  variant="secondary"
                  onClick={() => setOpenModal(true)}
                >
                  Logout
                </CustomButton>
              </Box>

              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <Menu
                  id="header-menu-appbar"
                  anchorEl={anchorElNav}
                  keepMounted
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    className="header-avatar-wrapper"
                  >
                    <Avatar className="header-avatar" alt="" src={image}>
                      {name?.charAt(0)}
                    </Avatar>
                    <p className="header-username">{name}</p>
                  </MenuItem>
                  <MenuItem
                    className="header-username"
                    onClick={() => setOpenModal(true)}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>

              <IconButton
                size="large"
                aria-haspopup="true"
                className="dot-icon-btn"
                aria-controls="header-menu-appbar"
                aria-label="account of current user"
                onClick={handleOpenNavMenu}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </ElevationScroll>
      <Modal
        open={openModal}
        onClick={handleLogout}
        onClose={onClose}
        isLogoutModal
        label="Logout"
        title="Are you sure you want to logout?"
      />
    </>
  );
};
