import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Box, Container } from "@mui/material";

import { VmsTable } from "./components";
import { useAuthenticationStore, useVmsStore } from "../../store";
import { useVMSDashboardData } from "../../apis";
import { useAuth, useInterval } from "../../hooks";
import {
  ANALYTICS_REFETCH_INTERVAL,
  GRID_COLUMNS,
  GRID_BREAKPOINTS,
  DATA_ERRORS,
  VMS_TABLE,
  IS_XXL_SCREEN,
  IS_TAB_SCREEN,
  IS_MOBILE_SCREEN,
} from "../../configs";
import { getTimeOflastUpdate } from "../../utils";
import "./index.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const defaultFilterOptions = [{"key":"venue","name":"venue"}]

if (!localStorage.getItem("vms_filter_options")) {
  localStorage.setItem("vms_filter_options", JSON.stringify(defaultFilterOptions));
}

const IS_FILTER_OPTIONS =
  localStorage.getItem("vms_filter_options") &&
  JSON.parse(localStorage.getItem("vms_filter_options"));

export const VMSDashboard = () => {
  // const auth = getAuth();

  // const { handleStartLoading, handleStopLoading, onLogout } = useAuth();
  // const { userIdToken } = useAuthenticationStore();

  const {
    venues,
    layoutLg,
    order,
    orderBy,
    page,
    rowsPerPage,
    maxHeight,
    lastUpdateTime,
    setVenues,
    setLayoutLg,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    setMaxHeight,
    setLastUpdateTime,
  } = useVmsStore();

  const [filters, setFilters] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(
    IS_FILTER_OPTIONS || []
  );
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const tableRef = useRef(null);
  const isFirstRender = useRef(true);
  const layoutRef = useRef(null);

  const analyticsParams = useMemo(() => {
    // console.log("analyticsParams.rowsPerPage: ", rowsPerPage);
    const params = {
      sort: orderBy,
      order: order === "desc" ? -1 : 1,
      skip: rowsPerPage * page.currentPage,
      per_page: rowsPerPage,
      filters: selectedFilterOptions.map((s) => s.key),
    };
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // userIdToken,
    selectedFilterOptions,
    page?.currentPage,
    order,
    orderBy,
    rowsPerPage,
  ]);

  const dashboardData = useVMSDashboardData(analyticsParams);

  const dashboard_data = dashboardData && dashboardData.data;
  const response_status = dashboardData && dashboardData.responseStatus;

  useEffect(() => {
    async function calculateHeights() {
      const offsetTopGap = IS_MOBILE_SCREEN || IS_XXL_SCREEN ? 97 : IS_TAB_SCREEN ? 44 : 53;
      const tablePaddingTop = IS_MOBILE_SCREEN ? 8 : 14;
      const tableHeaderHeight = IS_MOBILE_SCREEN ? 50 : 51;
      const footerHeight = 74;
      const tableBottomGap = 40;
      const rowHeight = 47;
      const { offsetTop } = tableRef.current;

      const tableBodyHeight = window.innerHeight - offsetTop - offsetTopGap - tableHeaderHeight - tablePaddingTop - footerHeight - tableBottomGap;

      const rows = parseInt(tableBodyHeight / rowHeight);

      setTimeout(() => {
        if (layoutRef?.current?.elementRef?.current?.clientHeight) {
          const availableHeight = layoutRef?.current?.elementRef?.current?.clientHeight - offsetTop - footerHeight - tablePaddingTop;

          setMaxHeight(availableHeight);
        }
      }, 100);

      if (rows <= 0) {
        setRowsPerPage(1);
        handleUpdateLayout(112);
        return;
      }

      setRowsPerPage(rows);
      handleUpdateLayout(tableBodyHeight);

    }

    const observer = new MutationObserver(mutations => {
      for (let mutation of mutations) {
        if (mutation.type === 'childList' && layoutRef?.current?.elementRef?.current?.clientHeight) {
          calculateHeights();
          observer.disconnect();  // Stop observing once the element is found and heights are calculated
        }
      }
    });
  
    if (layoutRef?.current?.elementRef?.current) {
      calculateHeights();  // Element is already there, calculate heights directly
    } else {
      observer.observe(document.body, { childList: true, subtree: true });  // Start observing for changes in the DOM
    }
  
    return () => observer.disconnect(); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef]);

  useEffect(() => {
    // console.log("dashboardData", dashboardData);
    if (!venues) {
      // handleStartLoading();
      setIsLoadingFilters(true);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUpdateData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("isUpdateData2", isUpdateData);
    if (!venues || isUpdateData) {
      const chk =
        analyticsParams?.access_token &&
        analyticsParams?.per_page > 0 &&
        isUpdateData;
      setIsFetch(chk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analyticsParams?.access_token,
    analyticsParams?.per_page,
    venues,
    isUpdateData,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setIsLoadingFilters(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsParams?.order, analyticsParams?.sort, analyticsParams?.skip]);

  useEffect(() => {
    // console.log("isUpdateData4", isUpdateData);
    if (venues) {
      setIsLoadingFilters(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (dashboardData && dashboardData.responseStatus !== 200) {
    //   getLastUpdateTime();
    //   if (DATA_ERRORS.includes(dashboardData?.status) && !auth?.currentUser)
    //     logout();
    //   return;
    // }

    if (dashboardData && dashboardData.responseStatus === 200) fetchAnalytics();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_data, response_status]);

  useEffect(() => {
    async function handleVisibilityChange() {
      if (!document.hidden) {
        const diff = getDifferenceOfUpdate();
        if (diff > ANALYTICS_REFETCH_INTERVAL) {
          setIsLoadingFilters(true);
          await dashboardData?.mutate();
        }
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateTime]);

  useInterval(() => {
    const diff = getDifferenceOfUpdate();
    setIsUpdateData(diff > ANALYTICS_REFETCH_INTERVAL);
  }, ANALYTICS_REFETCH_INTERVAL);

  const fetchAnalytics = useCallback(() => {
    // handleStopLoading();
    // handleStopLoading();
    setIsLoadingFilters(false);
    getLastUpdateTime();
    const filtersData = getFilters(dashboardData.data.filters);
    setFilters(filtersData);
    setVenues(dashboardData.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const getFilters = (data) => {
    const structureFilters = Object.entries(data)
      .filter((d) => d[0] !== "all")
      .map(([key, values]) => ({
        key,
        name: key.replace(/_/i, " "),
        values: values.map((v) => ({ key: v, name: v.replace(/_/i, " ") })),
      }));
    return structureFilters;
  };

  const getLastUpdateTime = () => {
    const updateTime = getTimeOflastUpdate();
    setLastUpdateTime(updateTime);
  };

  const getDifferenceOfUpdate = () => {
    return new Date().getTime() - new Date(lastUpdateTime).getTime();
  };

  const handleSelectFilter = (value) => setSelectedFilter(value);

  const handleSelectFilterOptions = (value) => {
    const index = selectedFilterOptions.findIndex((fo) => fo.key === value.key);

    if (index > -1) {
      const newArr = selectedFilterOptions;
      newArr.splice(index, 1);
      setSelectedFilterOptions([...newArr]);
      localStorage.setItem("vms_filter_options", JSON.stringify([...newArr]));
      return;
    }

    localStorage.setItem(
      "vms_filter_options",
      JSON.stringify([...selectedFilterOptions, value])
    );
    setSelectedFilterOptions([...selectedFilterOptions, value]);
  };

  const handleBackButtonClick = () => {
    setPage({
      ...page,
      currentPage: page.currentPage - 1,
      prevPage: page.prevPage - 1,
    });
  };

  const handleNextButtonClick = () => {
    setPage({
      ...page,
      currentPage: page.currentPage + 1,
      prevPage: page.prevPage + 1,
    });
  };

  const handleRequestSort = (e, property) => {
    if (orderBy !== property) {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUpdateLayout = (tableHeight) => {
    const currLayout = layoutLg;
    const additionalHeight = IS_TAB_SCREEN ? 245 : 230;

    const layout = calculateLayoutHeight(
      currLayout,
      additionalHeight,
      tableHeight
    );

    setLayoutLg(layout);
  };

  const calculateLayoutHeight = (data, additionalHeight, tableHeight) => {
    const layout = data.map((l) => l);
    const a = (tableHeight + additionalHeight) / 15.75;
    layout[0] = { ...layout[0], h: Number((a / 10).toFixed(2)) };
    return layout;
  };

  return (
    <Box>
      <Box
        className="dashboard-wrapper"
        sx={{
          paddingBottom: { xs: "0px", lg: "10px" },
          paddingTop: { lg: 4.5 },
          paddingLeft: { xl: 5.3, lg: 4.5 },
        }}
      >
        

        <Container maxWidth="xl" sx={{ padding: "0px !important" }}>

          <Box  className="last-update-time" > Last updated {lastUpdateTime}</Box>

          <Box className="static-layout-wrapper">
            <ResponsiveGridLayout
              className="layout"
              layouts={{ lg: layoutLg, md: layoutLg, xs: layoutLg }}
              breakpoints={GRID_BREAKPOINTS}
              cols={GRID_COLUMNS}
              isDraggable={false}
              isResizable={false}
              ref={layoutRef}
            >
              <Box key={VMS_TABLE}>
                <VmsTable
                  tableRef={tableRef}
                  maxHeight={maxHeight}
                  venues={venues?.results}
                  filters={filters}
                  isLoading={isLoadingFilters}
                  page={page.currentPage}
                  rowsPerPage={rowsPerPage}
                  dataLength={venues?.total_results}
                  selectedFilter={selectedFilter}
                  selectedFilterOptions={selectedFilterOptions}
                  onSelectFilters={handleSelectFilter}
                  onSelectFilterOptions={handleSelectFilterOptions}
                  handleBackButtonClick={handleBackButtonClick}
                  handleNextButtonClick={handleNextButtonClick}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              </Box>
            </ResponsiveGridLayout>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
