import React, { useMemo, memo } from "react";
import { Box, Paper } from "@mui/material";
import { Link } from "react-router-dom";

import { VenueRow } from "./VenueRow";
import { LoadingRow } from "./LoadingRow";
import {
  TableSearchHeader,
  TableWrapper,
  CustomTableFooter,
  CustomCalendar,
  DataMessage,
} from "../../../../components";
import { VENUE_TABLE_HEADINGS } from "../../../../configs";
import { stableSort, getComparator } from "../../../../utils";

export const VenueTable = memo(
  ({
    tableRef,
    calendarRef,
    maxHeight,
    dateRange,
    filters,
    selectedFilter,
    selectedFilterOptions,
    onSelectFilters,
    onSelectFilterOptions,
    venues,
    venueName,
    openTooltip,
    selectedAttribute,
    handleCloseTooltip,
    showAttributeDetails,

    page,
    rowsPerPage,
    dataLength,
    handleBackButtonClick,
    handleNextButtonClick,

    showCalendar,
    isLoading,
    order,
    orderBy,
    onRequestSort,
    onToggleCalendar,
    onClickSearch,
    onChangeDateRange,
  }) => {
    const arr = new Array(5).fill(1);

    const dataMessage = useMemo(() => {
      let heading = null;
      let description = null;
      let className = null;

      if (venues?.length <= 0) {
        if (selectedFilterOptions?.length > 0) {
          heading = "No recordings found";
          description =
            "There are no recordings for the dates that you have selected. Please come try again later.";
        } else {
          heading = "Waiting for your first recording";
          description =
            "There are no recordings in this venue.Please come try again later.";
        }
        className = "h-table-full";
      }
      return { heading, description, className };
    }, [venues, selectedFilterOptions]);

    const footer = (
      <>
        {venues?.length > 0 && (
          <CustomTableFooter
            name="venues"
            page={page}
            dataLength={dataLength}
            rowsPerPage={rowsPerPage}
            onClickBackButton={handleBackButtonClick}
            onClickNextButton={handleNextButtonClick}
          />
        )}
      </>
    );

    return (
      <Paper className="table-wrapper venue-table-wrapper">
        <Box className="venue-name-wrapper">
          <Link to="/" className="venue-name">
            VMS
          </Link>

          <Box className="venue-name">{">"}</Box>

          <Box onClick={() => window.location.reload()} className="venue-name">
            {venueName}
          </Box>
        </Box>

        <Box className="venue-filters-wrapper">
          <TableSearchHeader
            tableName={venueName}
            showFilters
            reverseFilters
            filters={filters}
            selectedFilter={selectedFilter}
            selectedFilterOptions={selectedFilterOptions}
            onSelectFilters={onSelectFilters}
            onSelectFilterOptions={onSelectFilterOptions}
          />

          <CustomCalendar
            calendarRef={calendarRef}
            dateRange={dateRange}
            showCalendar={showCalendar}
            onToggleCalendar={onToggleCalendar}
            onClickSearch={onClickSearch}
            onChangeDateRange={onChangeDateRange}
          />
        </Box>

        <Box
          ref={tableRef}
          className="card-content-wrapper cards-border-radius table-container-wrapper vms-wrapper"
        >
          <TableWrapper
            headings={VENUE_TABLE_HEADINGS}
            name="usersPage"
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            className={dataMessage?.className}
            footer={footer}
            maxHeight={maxHeight}
          >
            {isLoading &&
              arr?.map((m, i) => <LoadingRow key={`${m} - ${i}`} />)}

            {!isLoading && venues?.length <= 0 && (
              <DataMessage
                heading={dataMessage?.heading}
                description={dataMessage?.description}
                onClick={() => window.location.reload()}
              />
            )}

            {!isLoading &&
              venues &&
              stableSort(venues, getComparator(order, orderBy)).map(
                (venue, i) => (
                  <VenueRow
                    key={`${venue.track_id} - ${i}`}
                    venue={venue}
                    index={i}
                    openTooltip={openTooltip}
                    selectedAttribute={selectedAttribute}
                    handleCloseTooltip={handleCloseTooltip}
                    showAttributeDetails={showAttributeDetails}
                  />
                )
              )}
          </TableWrapper>
        </Box>
      </Paper>
    );
  }
);
