import React from "react";
import { Box, Skeleton, TableRow, TableCell } from "@mui/material";
import { VMS_TABLE_HEADINGS_WIDTHS } from "../../../../configs/constants/tables";

export const LoadingRow = () => {
  return (
    <TableRow className="analytics-table-row vms-row" >
      <TableCell >
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.id }}/>
      </TableCell>
     <TableCell >
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.name }}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.location }}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.battery_percentage }}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.up_time }}/>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" sx={{
            width: {
              sm: VMS_TABLE_HEADINGS_WIDTHS.status_history.sm,
              display: "flex",
              alignItems: "center",
              gap: "4px",
            },
          }}
        />
      </TableCell>
      <TableCell >
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.status }}/>
      </TableCell>
      <TableCell >
        <Skeleton variant="rectangular" sx={{ width: VMS_TABLE_HEADINGS_WIDTHS.last_recognition_attempt }}/>
      </TableCell>
      <TableCell >
        <Skeleton variant="rectangular" />
      </TableCell>
    </TableRow>
  );
};

// style={{ padding: "same as VmsRow", boxSizing: 'border-box' }}
