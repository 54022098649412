export const getDateFormat = (inputDate) => {
  const dateParts = inputDate.split("-");
  const year = dateParts[0];
  const month = new Date(inputDate).toLocaleString("default", {
    month: "long",
  });
  const day = dateParts[2];

  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};
