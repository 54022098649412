import { styled } from "@mui/material/styles";

import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const drawerPaper = {
  backgroundColor: "#101010",
  paddingTop: 0,
  justifyContent: "space-between",
};

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  maxWidth: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": { ...openedMixin(theme), ...drawerPaper },
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      ...drawerPaper,
      width: "92px",
    },
  }),
}));
