import React, { memo } from "react";
import { TableRow, TableCell } from "@mui/material";

import { CustomTooltip } from "../../../../components";
import {
  getDateAndTimeWithSeconds,
  getTimeWithSeconds,
} from "../../../../utils";

export const VenueRow = memo(
  ({
    venue,
    index,
    openTooltip,
    selectedAttribute,
    handleCloseTooltip,
    showAttributeDetails,
  }) => {
    return (
      <TableRow className="analytics-table-row">
        <TableCell>
          {!venue.time_recorded || venue.time_recorded === "undefined"
            ? "--"
            : getDateAndTimeWithSeconds(venue.time_recorded, true)}
        </TableCell>
        <TableCell>
          {!venue.time_recognised || venue.time_recognised === "undefined"
            ? "--"
            : getTimeWithSeconds(venue.time_recognised)}
        </TableCell>
        <TableCell>
          {!venue.title ? (
            <span></span>
          ) : (
            <CustomTooltip
              index={index}
              isShowComplete={true}
              data={venue.track_id}
              label={venue.title}
              tableName="venue-id"
              openTooltip={openTooltip}
              selectedAttribute={selectedAttribute}
              handleCloseTooltip={handleCloseTooltip}
              showAttributeDetails={showAttributeDetails}
            />
          )}
        </TableCell>
        <TableCell>{venue.artist}</TableCell>
        <TableCell>{venue.source}</TableCell>
        <TableCell>
          {!venue.recording_length || venue.recording_length === "undefined"
            ? "--"
            : `${venue.recording_length} ms`}
        </TableCell>
      </TableRow>
    );
  }
);
