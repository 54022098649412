import React, { useMemo, memo } from "react";
import { Box, Paper } from "@mui/material";
import { VmsRow } from "./VmsRow";
import { LoadingRow } from "./LoadingRow";
import {
  TableSearchHeader,
  TableWrapper,
  CustomTableFooter,
  DataMessage,
} from "../../../../components";
import {
  IS_MD_SCREEN,
  IS_MOBILE_SCREEN,
  VMS_TABLE_HEADINGS,
} from "../../../../configs";
import { stableSort, getComparator } from "../../../../utils";
export const VmsTable = memo(
  ({
    tableRef,
    maxHeight,
    filters,
    selectedFilter,
    selectedFilterOptions,
    onSelectFilters,
    onSelectFilterOptions,
    venues,
    page,
    rowsPerPage,
    dataLength,
    handleBackButtonClick,
    handleNextButtonClick,
    isLoading,
    order,
    orderBy,
    onRequestSort,
  }) => {
    const arr = new Array(5).fill(1);

    const dataMessage = useMemo(() => {
      let heading = null;
      let description = null;
      let className = null;
      if (venues?.length <= 0) {
        if (selectedFilterOptions?.length > 0) {
          heading = "No recordings found";
          description =
            "There are no venues found in this area. Please try again later.";
        } else {
          heading = "Waiting for your first recording";
          description =
            "There are no active devices at the moment. Please come try again later.";
        }
        className = "h-table-full";
      }
      return { heading, description, className };
    }, [venues, selectedFilterOptions]);

    const footer = (
      <>
        {venues?.length > 0 && (
          <CustomTableFooter
            name="venues"
            page={page}
            dataLength={dataLength}
            rowsPerPage={rowsPerPage}
            onClickBackButton={handleBackButtonClick}
            onClickNextButton={handleNextButtonClick}
          />
        )}
      </>
    );

    return (
      <Paper className="table-wrapper">
        <TableSearchHeader
          tableName="VMS"
          showFilters
          reverseFilters={!IS_MD_SCREEN || IS_MOBILE_SCREEN}
          filters={filters}
          selectedFilter={selectedFilter}
          selectedFilterOptions={selectedFilterOptions}
          onSelectFilters={onSelectFilters}
          onSelectFilterOptions={onSelectFilterOptions}
        />

        <Box
          ref={tableRef}
          className="card-content-wrapper cards-border-radius table-container-wrapper vms-wrapper"
        >
          <TableWrapper
            headings={VMS_TABLE_HEADINGS}
            name="usersPage"
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            className={dataMessage?.className}
            footer={footer}
            maxHeight={maxHeight}
          >
            {isLoading &&
              arr?.map((m, i) => <LoadingRow key={`${m} - ${i}`} />)}

            {!isLoading && venues?.length <= 0 && (
              <DataMessage
                heading={dataMessage?.heading}
                description={dataMessage?.description}
                onClick={() => window.location.reload()}
              />
            )}

            {!isLoading &&
              venues &&
              venues.map((venue, i) => (
                <VmsRow key={venue.venue_id} venue={venue} />
              ))}
          </TableWrapper>
        </Box>
      </Paper>
    );
  }
);
