import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_ADDRESS;

const headers = {};

export const axiosClient = () =>
  axios.create({
    headers,
    baseURL,
  });
