import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import "./index.css";

export const TableWrapper = ({
  headings,
  maxHeight,
  className,
  children,
  order,
  orderBy,
  onRequestSort,
  ref,
  footer,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer
      sx={{ height: maxHeight, maxHeight }}
      className={className || ""}
      ref={ref}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headings.map((heading, i) =>
              heading.isSort ? (
                <TableSortCell
                  key={`${heading.id}-${i}`}
                  heading={heading}
                  order={order}
                  orderBy={orderBy}
                  onClick={createSortHandler}
                />
              ) : (
                <TableCell
                  key={`${heading.id}-${i}`}
                  className="heading-cell"
                  style={{ width: `${heading.width}` }}
                >
                  {heading.name}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {footer}
      </Table>
    </TableContainer>
  );
};

const TableSortCell = ({ heading, order, orderBy, onClick, ...props }) => {
  return (
    <TableCell
      {...props}
      sortDirection={orderBy === heading.id ? order : false}
      style={{ width: `${heading.width}` }}
      className="sort-heading-cell heading-cell"
    >
      <TableSortLabel
        active={orderBy === heading.id}
        direction={orderBy === heading.id ? order : "asc"}
        onClick={onClick(heading.id)}
      >
        {heading.name}
      </TableSortLabel>
    </TableCell>
  );
};
