export const getTimeOflastUpdate = () => {
  const date = new Date();
  const updateTime = `${date
    .toDateString()
    .split(" ")
    .slice(1)
    .join(" ")} ${date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })} `;
  return updateTime;
};
