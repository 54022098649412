import { combine } from "zustand/middleware";
import { create } from "zustand";

const getDefaultValues = () => ({
  open: false,
});

export const useSideBar = create(
  combine(getDefaultValues(), (set) => ({
    setIsopen: (open) =>
      set(() => ({
        open: open,
      })),
  }))
);
