import React, { useState, useCallback, useEffect } from "react";
import { Box, Checkbox, MenuItem } from "@mui/material";

import { StyledButton, StyledMenu } from "./styles";
import ArrowIcon from "../../assets/svgs/arrow-up-outlined-icon.svg";
import { CheckboxIcon, CheckedIcon } from "../../assets/svgIcons";

export const Filter = ({
  reverseFilters,
  filters,
  selectedFilter,
  selectedFilterOptions,
  onSelectFilters,
  onSelectFilterOptions,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("click", handleClose);
      return () => window.removeEventListener("click", handleClose);
    }
  }, []);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const getColor = (value) => {
    const index = selectedFilterOptions?.findIndex(
      (fo) => fo.key === value.key
    );
    if (index > -1) {
      return "#e0e0e0";
    }
    return "";
  };

  const isChecked = useCallback(
    (value) => {
      const index = selectedFilterOptions?.findIndex(
        (fo) => fo.key === value.key
      );
      return index !== -1;
    },
    [selectedFilterOptions]
  );

  return (
    <Box sx={{ position: "relative", width: { xs: "100%", lg: "auto" } }}>
      <StyledButton
        id="users-filters"
        aria-haspopup="true"
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleClick}
        open={open}
        endIcon={<img src={ArrowIcon} alt="" />}
        className={`${
          selectedFilterOptions?.length > 0 ? "selected-filters-btn" : ""
        }`}
        sx={{ width: { xs: "100%", lg: 160 }, height: "40px" }}
      >
        <span>
          {selectedFilterOptions?.length > 0
            ? selectedFilterOptions.map((option) => option.name).join(", ")
            : "Filter"}
        </span>
      </StyledButton>
      {open && (
        <StyledMenu
          id="users-filters"
          open={open}
          value={selectedFilter}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
          reverseFilters={reverseFilters}
          style={{
            position: "absolute",
            right: reverseFilters ? 0 : "unset",
          }}
        >
          <div className="menu-dropdown-wrapper">
            <div>
              {filters?.map((item) => (
                <MenuItem
                  key={item.name}
                  disableRipple
                  onMouseEnter={() => onSelectFilters(item)}
                  style={{
                    color: `${
                      selectedFilter?.name && item.name === selectedFilter?.name
                        ? "#e0e0e0"
                        : "#757575"
                    }`,
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </div>

            <div>
              {selectedFilter &&
                selectedFilter.values.map((value) => (
                  <div
                    key={value.key}
                    className="filter-options"
                    role="checkbox"
                    aria-checked
                    onClick={() => onSelectFilterOptions(value)}
                  >
                    <Checkbox
                      checked={isChecked(value)}
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedIcon />}
                    />
                    <MenuItem disableRipple style={{ color: getColor(value) }}>
                      {value.name}
                    </MenuItem>
                  </div>
                ))}
            </div>
          </div>
        </StyledMenu>
      )}
    </Box>
  );
};
