import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const StyledButton = styled((props) => <Button {...props} />)(
  ({ theme, ...props }) => ({
    width: 160,
    justifyContent: "space-between",
    backgroundColor: "#1b2130",
    color: "#757575",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "capitalize",
    padding: "9px 13.4px 9px 16px",
    "& span:first-child": {
      width: "102px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
    },
    "&.selected-filters-btn": {
      color: "#e0e0e0",
    },
    "&:hover": {
      backgroundColor: "#1b2130",
    },
    "& img": {
      transform: `rotate(${props.open ? "0deg" : "-180deg"})`,
    },
  })
);

export const StyledMenu = styled((props) => <div elevation={0} {...props} />)(
  ({ theme, ...props }) => ({
    zIndex: 99999999,
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 160,
    backgroundColor: "#1b2130",
    position: "absolute",
    right: `${props.reverseFilters ? "0px" : "unset"}`,
    boxShadow:
      "0px 0px 0px 0px #0000001A, -1px 4px 8px 0px #0000001A,-5px 15px 15px 0px #00000017,-11px 33px 21px 0px #0000000D, -20px 58px 25px 0px #00000003, -31px 91px 27px 0px #00000000",
    "& .menu-dropdown-wrapper": {
      display: "flex",
      flexDirection: `${props.reverseFilters ? "row-reverse" : "row"}`,
      "& > div:first-child": {
        borderColor: `${props.value ? "#e0e0e033" : "transparent"}`,
        borderRightWidth: `${props.reverseFilters ? "" : "1px"}`,
        borderLeftWidth: `${props.reverseFilters ? "1px" : ""}`,
        borderRightStyle: `${props.reverseFilters ? "" : "solid"}`,
        borderLeftStyle: `${props.reverseFilters ? "solid" : ""}`,
        width: "100%",
      },
    },
    "& .MuiMenuItem-root": {
      minWidth: 160,
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      color: "#757575",
      paddingTop: "12px",
      paddingBottom: "10px",
      textTransform: "capitalize",
      "&:hover": {
        color: "#e0e0e0",
      },
    },
    "& .filter-options": {
      borderBottom: "1px solid #e0e0e033",
      display: "flex",
      "& .MuiMenuItem-root": {
        paddingLeft: "1px",
      },
      "&:hover": {
        backgroundColor: "#10131b",
        "& li": {
          color: "#e0e0e0",
        },
      },
      "& .MuiCheckbox-root": {
        color: "#d9d9d9",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
  })
);
