import useSWRImmutable from "swr";

import {
  VMS_VENUE_DASHBOARD_DATA,
  ANALYTICS_REFETCH_INTERVAL,
} from "../configs";
import { fetcher } from "../utils";

export const useVMSVenueDashboardData = (payload) => {

  if (payload.per_page > 0)
    console.log("Getting VMS Venue Dashboard Data...");

  const { data, mutate } = useSWRImmutable(
    payload.per_page > 0 ? [VMS_VENUE_DASHBOARD_DATA.API, payload] : null,
    fetcher,
    {
      refreshInterval: ANALYTICS_REFETCH_INTERVAL,
      revalidateOnFocus: false,
    }
  );
  try {
    if (data) {
      return {
        data: data.data.data,
        status: data.data.status,
        responseStatus: data.status,
        mutate: mutate,
      };
    }
  } catch (err) {
    return {
      data: null,
      status: null,
      responseStatus: null,
      mutate: mutate,
    };
  }
};
