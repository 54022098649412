import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAuth } from "firebase/auth";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Box, Container, Snackbar } from "@mui/material";

import {
  INITIAL_METRICS_TAB,
  INITIAL_RECOGNITION_TAB,
  INITIAL_PAGES,
  INITIAL_ROWS_PER_PAGE,
  METRIC_COUNT,
  RECENT_ACCOUNTS,
  ACTIVE_USERS,
  DAILY_ACTIVE_USERS,
  RECOGNITION,
  REPORTS,
  LAYOUT,
  LAYOUT_MD,
  LAYOUT_XS,
  GRID_COLUMNS,
  GRID_BREAKPOINTS,
  DATA_ERRORS,
  ANALYTICS_REFETCH_INTERVAL,
} from "../../configs";

import {
  Chart,
  TopTracks,
  ReportsTable,
  AccountsTable,
  ActiveUsers,
} from "./components";

import { useAnalyticsData } from "../../apis";
import { useAuth, useInterval } from "../../hooks";
import {
  addCountryNamesToData,
  getDate,
  getTimeOflastUpdate,
  interpolateColor,
} from "../../utils";
import { useAuthenticationStore, useAnalyticsStore } from "../../store";
import FetchToken from "../../utils/fetchToken";

import UserIcon from "../../assets/svgs/user-icon.svg";
import SessionIcon from "../../assets/svgs/session-icon.svg";
import DownloadIcon from "../../assets/svgs/download-icon.svg";
import "./index.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Analytics = () => {
  const auth = getAuth();

  const { refreshToken, userIdToken } = useAuthenticationStore();

  const { getAuthToken } = FetchToken();
  const { handleStartLoading, handleStopLoading, onLogout } = useAuth();

  const {
    analytics,
    appMetrics,
    recognition,
    activeUsers,
    usersList,
    tracks,
    usersByCountry,
    setAnalytics,
    setAppMetrics,
    setRecognition,
    setActiveUsers,
    setUsersList,
    setTracks,
    setUsersByCountry,
  } = useAnalyticsStore();

  const [showTracks, setShowTracks] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [open, setOpen] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [error, setError] = useState(null);
  const [tracksTab, setTracksTab] = useState("today");
  const [metricsTab, setMetricsTab] = useState(INITIAL_METRICS_TAB);
  const [recognitionTab, setRecognitionTab] = useState(INITIAL_RECOGNITION_TAB);
  const [page, setPage] = useState(INITIAL_PAGES);
  const [rowsPerPage] = useState(INITIAL_ROWS_PER_PAGE);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  const [activeUsersTab, setActiveUsersTab] = useState({ tabType: "days" });

  const analyticsParams = useMemo(() => {
    const params = {
      user_id: localStorage.getItem("user_id"),
      access_token: userIdToken || refreshToken,
    };

    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdToken]);

  useEffect(() => {
    getAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analyticsData = useAnalyticsData(analyticsParams, isFetch);

  const analytics_data = analyticsData && analyticsData.data;
  const response_status = analyticsData && analyticsData.responseStatus;

  useEffect(() => {
    if (!analytics) {
      handleStartLoading();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUpdateData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!analytics || isUpdateData) {
      const chk = analyticsParams?.access_token && isUpdateData;

      setIsFetch(chk);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analyticsParams?.access_token,
    analyticsParams?.per_page,
    analytics,
    isUpdateData,
  ]);

  useEffect(() => {
    if (analyticsData && analyticsData.responseStatus !== 200) {
      getLastUpdateTime();

      if (DATA_ERRORS.includes(analytics?.status) && !auth?.currentUser)
        onLogout();
      return;
    }

    if (analyticsData && analyticsData.responseStatus === 200) {
      fetchAnalytics();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics_data, response_status]);

  useEffect(() => {
    async function handleVisibilityChange() {
      if (!document.hidden) {
        const diff = getDifferenceOfUpdate();
        if (diff > ANALYTICS_REFETCH_INTERVAL) await analyticsData?.mutate();
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateTime]);

  useInterval(() => {
    const diff = getDifferenceOfUpdate();

    setIsUpdateData(diff > ANALYTICS_REFETCH_INTERVAL);
  }, ANALYTICS_REFETCH_INTERVAL);

  const fetchAnalytics = useCallback(() => {
    handleStopLoading();
    getLastUpdateTime();
    setAnalytics(analyticsData.data);

    const user_accounts_data = addCountryNamesToData(
      analyticsData.data?.recent_account_creations,
      "country_active"
    );
    setUsersList(user_accounts_data);

    const metricsData = handleChangeMetricsFilters(
      {},
      analyticsData.data,
      metricsTab.tabType,
      metricsTab.metricsType
    );

    const totalMetricsCount = [
      {
        title: "Total Downloads",
        image: DownloadIcon,
        metricsType: "download_data",
        count: analyticsData.data?.app_metrics?.download_data?.total,
        trend: analyticsData.data?.app_metrics?.download_data?.trends,
      },
      {
        title: "Total Users",
        image: UserIcon,
        metricsType: "user_data",
        count: analyticsData.data?.app_metrics?.user_data?.total,
        trend: analyticsData.data?.app_metrics?.user_data?.trends,
      },
      {
        title: "Total Sessions",
        image: SessionIcon,
        metricsType: "session_data",
        count: analyticsData.data?.app_metrics?.session_data?.total,
        trend: analyticsData.data?.app_metrics?.session_data?.trends,
      },
    ];

    setAppMetrics({ ...metricsData, totalCount: totalMetricsCount });

    const recognitionData = handleChangeRecognitionFilters(
      {},
      analyticsData.data,
      recognitionTab.tabType,
      recognitionTab.metricsType
    );

    const totalRecognitionCount = [
      {
        id: 1,
        title: "Recognition Attempts",
        metricsType: "recognition_attempts",
        count: analyticsData.data?.recognition?.recognition_attempts.total,
        trend: analyticsData.data?.recognition?.recognition_attempts.trends,
      },
      {
        id: 2,
        title: "Tracks Recognised",
        metricsType: "tracks_recognised",
        count: analyticsData.data?.recognition?.tracks_recognised.total,
        trend: analyticsData.data?.recognition?.tracks_recognised.trends,
      },
    ];

    setRecognition({ ...recognitionData, totalCount: totalRecognitionCount });

    const activeUsersData = handleActiveUsersData({}, analyticsData.data);

    setActiveUsers(activeUsersData);

    handleChangeTracksFilters(null, tracksTab, analyticsData.data);

    handleChangeActiveUsersFilters(
      null,
      null,
      activeUsersTab?.tabType,
      analyticsData.data
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsData]);

  const getDifferenceOfUpdate = () => {
    return new Date().getTime() - new Date(lastUpdateTime).getTime();
  };

  const getLastUpdateTime = () => {
    const updateTime = getTimeOflastUpdate();

    setLastUpdateTime(updateTime);
  };

  const handleChangeMetricsFilters = (e, data, tabType, metricsType) => {
    setMetricsTab({
      tabType,
      metricsType,
    });

    const currentData = data || analytics;

    const dates = currentData?.app_metrics[metricsType][tabType]
      .slice(0)
      .reverse()
      .map((day) => {
        const date = getDate(day.date);
        return date;
      });

    if (metricsType === "download_data") {
      const androidCounts = currentData?.app_metrics[metricsType][tabType]
        .slice(0)
        .reverse()
        .map((count) => count.count.android);

      const iosCounts = currentData?.app_metrics[metricsType][tabType]
        .slice(0)
        .reverse()
        .map((count) => count.count.ios);

      const totalCounts = currentData?.app_metrics[metricsType][tabType]
        .slice(0)
        .reverse()
        .map((count) => count.count.total);

      setAppMetrics({
        ...appMetrics,
        labels: dates,
        data: [androidCounts, iosCounts, totalCounts],
      });
      return {
        labels: dates,
        data: [androidCounts, iosCounts, totalCounts],
      };
    }

    const counts = currentData?.app_metrics[metricsType][tabType]
      .slice(0)
      .reverse()
      .map((count) => count.count);
    setAppMetrics({
      ...appMetrics,
      labels: dates,
      data: counts,
    });
    return {
      labels: dates,
      data: counts,
    };
  };

  const handleChangeRecognitionFilters = (e, data, tabType, metricsType) => {
    setRecognitionTab({ tabType, metricsType });

    const currentData = data || analytics;

    const dates = currentData?.recognition[metricsType][tabType]
      .slice(0)
      .reverse()
      .map((day) => {
        const date = getDate(day.date);

        return date;
      });

    const counts = currentData?.recognition[metricsType][tabType]
      .slice(0)
      .reverse()
      .map((count) => count.count);

    setRecognition({
      ...recognition,
      labels: dates,
      data: counts,
    });

    return {
      labels: dates,
      data: counts,
    };
  };

  const handleChangeTracksFilters = (e, tabType, data) => {
    setTracksTab(tabType);

    const currentData = data || analytics;
    const tracksData = currentData?.top_tracks[tabType];

    setTracks(tracksData);
  };

  const handleChangeActiveUsersFilters = (e, filter, tabType, data) => {
    const tab =
      tabType === "days"
        ? "day_ago"
        : tabType === "weeks"
        ? "week_ago"
        : tabType === "months"
        ? "month_ago"
        : "";
    setActiveUsersTab({ tabType });

    const currentData = data || analytics;
    const countryData = currentData?.active_users_by_country[tab];

    const updatedData = addCountryNamesToData(
      countryData?.results,
      "country_code"
    );

    //get array of objects in which objects only contains country code & color against it. We need it for jvector map.
    const countryColorsArr = countryData?.results?.map((c) => {
      const test = c["country_code"];
      const count = c["count"];

      // Define the color range
      const color1 = "#fabda3";
      const color2 = "#ed0000";

      // Map the count value from the range [0.01, 1] to [1, 100]
      const normalizedPercentage = ((count - 0.01) / 0.99) * 99 + 1;

      const interpolatedColor = interpolateColor(
        color1,
        color2,
        normalizedPercentage / 100
      );

      return { [test]: interpolatedColor };
    });

    //convert the above array to an object as jvector map needs an object to display colors on map.
    const countryColorsObj = countryColorsArr.reduce(
      (a, b) => Object.assign(a, b),
      {}
    );

    setUsersByCountry({
      count: countryData?.count,
      data: updatedData,
      countryColors: countryColorsObj,
    });
  };

  const handleActiveUsersData = (e, data) => {
    const dates = data?.active_users
      .slice(0)
      .reverse()
      .map((date) => {
        const d = getDate(date.date);

        return d;
      });

    const counts = data?.active_users
      .slice(0)
      .reverse()
      .map((count) => count.count);

    return {
      labels: dates,
      data: counts,
    };
  };

  const handleCloseTooltip = (id, isDisplayMessage) => {
    setOpenTooltip({
      [id]: false,
    });

    if (isDisplayMessage) setOpen(true);
  };

  const showAttributeDetails = (values, index) => {
    let newArr = [];
    if (typeof values === "object") {
      newArr = Object.keys(values).map(function (key) {
        if (key === "image_url") {
          return (
            <span
              className="cursor-pointer"
              onClick={() => {
                const image_window = window.open("", "_blank");
                image_window.document.write(`
                    <html>
                      <head>
                      </head>
                      <body>token
                        <img src=${values[key]} alt="Example" width="100%" height="100%" style="object-fit:contain;">
                      </body>
                    </html>
              `);
              }}
            >
              Image
            </span>
          );
        }
        return values[key];
      });
    } else {
      setOpenTooltip({ [`${values}-${index}`]: true });
      newArr = [`${values}-${index}`];
    }
    setSelectedAttribute(newArr);
  };

  const handleTracksToggle = () => {
    setShowTracks(!showTracks);
  };

  const getCurrentPaginationState = (name) => {
    switch (name) {
      case "accountsPage":
        return "prevAccountsPage";
      case "reportsPage":
        return "prevReportsPage";
      case "tracksPage":
        return "prevTracksPage";
      default:
        break;
    }
  };

  const handleBackButtonClick = (e) => {
    const prevState = getCurrentPaginationState(e.target.name);

    setPage({
      ...page,
      [e.target.name]: page[e.target.name] - 1,
      [prevState]: page[prevState] - 1,
    });
  };

  const handleNextButtonClick = (e) => {
    const prevState = getCurrentPaginationState(e.target.name);
    setPage({
      ...page,
      [e.target.name]: page[e.target.name] + 1,
      [prevState]: page[prevState] + 1,
    });
  };

  return (
    <Box>
      <Box>
        <Box className="dashboard-wrapper">
          <Box className="last-update-time">
            Updated last on {lastUpdateTime}
          </Box>

          <Container
            maxWidth="xl"
            sx={{
              padding: "0px !important",
            }}
          >
            <Box>
              <ResponsiveGridLayout
                className="layout"
                layouts={{
                  lg: LAYOUT,
                  md: LAYOUT_MD,
                  xs: LAYOUT_XS,
                }}
                breakpoints={GRID_BREAKPOINTS}
                cols={GRID_COLUMNS}
                isDraggable={false}
                isResizable={false}
              >
                <Box key={METRIC_COUNT}>
                  <Chart
                    cardHeading="Metric Count"
                    chartHeight="254px"
                    tab={metricsTab}
                    graphData={appMetrics}
                    handleChangeFilters={handleChangeMetricsFilters}
                  />
                </Box>

                <Box key={ACTIVE_USERS}>
                  <ActiveUsers
                    cardHeading="Active Users"
                    chartHeight="370px"
                    tab={activeUsersTab}
                    users={usersByCountry}
                    handleChangeFilters={handleChangeActiveUsersFilters}
                  />
                </Box>

                <Box key={RECENT_ACCOUNTS}>
                  <AccountsTable
                    accounts={usersList}
                    openTooltip={openTooltip}
                    selectedAttribute={selectedAttribute}
                    dataLength={usersList?.length}
                    page={page.accountsPage}
                    rowsPerPage={rowsPerPage.accountsRows}
                    handleCloseTooltip={handleCloseTooltip}
                    showAttributeDetails={showAttributeDetails}
                    handleBackButtonClick={handleBackButtonClick}
                    handleNextButtonClick={handleNextButtonClick}
                  />
                </Box>

                <Box key={DAILY_ACTIVE_USERS}>
                  <Chart
                    cardHeading="Daily Active Users"
                    chartHeight="400px"
                    hideFilters
                    graphData={activeUsers}
                    isActiveUsersChart
                  />
                </Box>

                <Box key={RECOGNITION} className="recognition-wrapper">
                  {showTracks ? (
                    <TopTracks
                      cardHeading="Recognition"
                      tab={tracksTab}
                      tracks={tracks}
                      page={page.tracksPage}
                      rowsPerPage={rowsPerPage.tracksRows}
                      handleTracksToggle={handleTracksToggle}
                      handleBackButtonClick={handleBackButtonClick}
                      handleNextButtonClick={handleNextButtonClick}
                      handleChangeFilters={handleChangeTracksFilters}
                    />
                  ) : (
                    <Chart
                      cardHeading="Recognition"
                      chartHeight="304px"
                      tab={recognitionTab}
                      graphData={recognition}
                      showTracks={showTracks}
                      handleChangeFilters={handleChangeRecognitionFilters}
                      handleTracksToggle={handleTracksToggle}
                    />
                  )}
                </Box>

                <Box key={REPORTS}>
                  <ReportsTable
                    reports={analytics?.recent_reports}
                    openTooltip={openTooltip}
                    selectedAttribute={selectedAttribute}
                    page={page.reportsPage}
                    rowsPerPage={rowsPerPage.reportsRows}
                    dataLength={analytics?.recent_reports?.length}
                    handleCloseTooltip={handleCloseTooltip}
                    showAttributeDetails={showAttributeDetails}
                    handleBackButtonClick={handleBackButtonClick}
                    handleNextButtonClick={handleNextButtonClick}
                  />
                </Box>
              </ResponsiveGridLayout>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box className={open ? "message-alert" : "error-alert"}>
        <Snackbar
          anchorOrigin={{
            vertical: open ? "top" : "bottom",
            horizontal: open ? "right" : "center",
          }}
          autoHideDuration={3000}
          open={open || error}
          onClose={() => {
            if (open) setOpen(false);
            if (error) setError(null);
          }}
          message={error ? error : open ? "Copied!!!" : ""}
        />
      </Box>
    </Box>
  );
};
