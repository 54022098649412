export const METRIC_COUNT = "metric-count";
export const ACTIVE_USERS = "active-users";
export const RECENT_ACCOUNTS = "recent-accounts";
export const DAILY_ACTIVE_USERS = "daily-active-users";
export const RECOGNITION = "recognition";
export const REPORTS = "reports";
export const USERS_TABLE = "users-table";
export const VMS_TABLE = "vms-table";
export const VMS_VENUE_TABLE = "vms-venue-table";

export const LAYOUT = [
  { i: "metric-count", x: 0, y: 0, w: 0.8, h: 3.3 },
  { i: "active-users", x: 2, y: 0, w: 1.2, h: 3.3 },
  { i: "recent-accounts", x: 2, y: 0, w: 2, h: 4 },
  { i: "daily-active-users", x: 0, y: 0, w: 0.8, h: 3.52 },
  { i: "recognition", x: 0, y: 0, w: 0.8, h: 3.5 },
  { i: "reports", x: 2, y: 0, w: 1.2, h: 7.12 },
];

export const LAYOUT_MD = [
  { i: "metric-count", x: 0, y: 0, w: 1, h: 4 },
  { i: "active-users", x: 0, y: 0, w: 1, h: 4.9 },
  { i: "recent-accounts", x: 0, y: 0, w: 1, h: 4.1 },
  { i: "daily-active-users", x: 0, y: 0, w: 1, h: 3.4 },
  { i: "recognition", x: 0, y: 0, w: 1, h: 3.5 },
  { i: "reports", x: 0, y: 0, w: 1, h: 4.1 },
];

export const LAYOUT_XS = [
  { i: "metric-count", x: 0, y: 0, w: 1, h: 2.81 },
  { i: "active-users", x: 0, y: 0, w: 1, h: 4.5 },
  { i: "recent-accounts", x: 0, y: 0, w: 1, h: 3.2 },
  { i: "daily-active-users", x: 0, y: 0, w: 1, h: 2.24 },
  { i: "recognition", x: 0, y: 0, w: 1, h: 3.1 },
  { i: "reports", x: 0, y: 0, w: 1, h: 3.2 },
];

export const USER_LAYOUT_LG = [{ i: "users-table", x: 0, y: 0, w: 2, h: 3.55 }];
export const USER_LAYOUT_MD = [{ i: "users-table", x: 0, y: 0, w: 2, h: 3.55 }];
export const USER_LAYOUT_XS = [{ i: "users-table", x: 0, y: 0, w: 2, h: 3.55 }];

export const VMS_LAYOUT_LG = [{ i: "vms-table", x: 0, y: 0, w: 2, h: 3.55 }];
export const VMS_LAYOUT_MD = [{ i: "vms-table", x: 0, y: 0, w: 2, h: 3.55 }];
export const VMS_LAYOUT_XS = [{ i: "vms-table", x: 0, y: 0, w: 2, h: 3.55 }];

export const VMS_VENUE_LAYOUT_LG = [
  { i: "vms-venue-table", x: 0, y: 0, w: 2, h: 3.55 },
];
export const VMS_VENUE_LAYOUT_MD = [
  { i: "vms-venue-table", x: 0, y: 0, w: 2, h: 3.55 },
];
export const VMS_VENUE_LAYOUT_XS = [
  { i: "vms-venue-table", x: 0, y: 0, w: 2, h: 3.55 },
];

export const GRID_BREAKPOINTS = {
  xl: 1536,
  lg: 1200,
  md: 900,
  sm: 600,
  xs: 480,
};

export const GRID_COLUMNS = { xl: 2, lg: 2, md: 1, sm: 1, xs: 1, xxs: 1 };
