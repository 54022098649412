import { useState, useEffect } from "react";

export const useDetectOutsideClick = (ref) => {
  const [isOutside, setIsOutside] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOutside(true);
        return;
      }
      setIsOutside(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return isOutside;
};
