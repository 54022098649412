import { combine } from "zustand/middleware";
import { create } from "zustand";
import { VMS_VENUE_LAYOUT_LG } from "../../configs";

const getDefaultValues = () => ({
  vmsVenuesData: null,
  layoutLg: VMS_VENUE_LAYOUT_LG,
  lastUpdateTime: null,
});

export const useVmsVenuesStore = create(
  combine(getDefaultValues(), (set) => ({
    setVmsVenuesData: (vmsVenuesData) => {
      set({ vmsVenuesData });
    },
    setLayoutLg: (layout) => {
      set({ layoutLg: layout });
    },
    setLastUpdateTime: (lastUpdateTime) => {
      set({ lastUpdateTime });
    },
  }))
);
