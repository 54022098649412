import { combine } from "zustand/middleware";
import { create } from "zustand";
import { TOTAL_METRICS_COUNT } from "../../configs";

const getDefaultValues = () => ({
  analytics: null,
  appMetrics: TOTAL_METRICS_COUNT,
  recognition: null,
  activeUsers: null,
  usersList: null,
  tracks: null,
  usersByCountry: null,
});

export const useAnalyticsStore = create(
  combine(getDefaultValues(), (set) => ({
    setAnalytics: (analytics) => {
      set({ analytics });
    },

    setAppMetrics: (appMetrics) => {
      set({ appMetrics });
    },

    setRecognition: (recognition) => {
      set({ recognition });
    },

    setActiveUsers: (activeUsers) => {
      set({ activeUsers });
    },

    setUsersList: (usersList) => {
      set({ usersList });
    },

    setTracks: (tracks) => {
      set({ tracks });
    },

    setUsersByCountry: (usersByCountry) => {
      set({ usersByCountry });
    },
  }))
);
