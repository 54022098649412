import { axiosClient } from "../axiosClient";

export const fetcher = async (url, data) => {
  try {
    // console.log("fetcher - start");
    const res = await axiosClient().post(url, data);
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};
