import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Box, Container, Snackbar } from "@mui/material";

import { VenueTable } from "./components";
import { useVMSVenueDashboardData } from "../../apis";
import { useAuthenticationStore, useVmsVenuesStore } from "../../store";
import { useAuth, useInterval, useDetectOutsideClick } from "../../hooks";
import {
  GRID_COLUMNS,
  GRID_BREAKPOINTS,
  DATA_ERRORS,
  MIN_DATE_RANGE,
  ANALYTICS_REFETCH_INTERVAL,
  VMS_VENUE_TABLE,
  IS_XXL_SCREEN,
  IS_MD_SCREEN,
  IS_TAB_SCREEN,
  IS_MOBILE_SCREEN,
} from "../../configs";
import { getTimeOflastUpdate } from "../../utils";
import "./index.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const IS_FILTER_OPTIONS =
  localStorage.getItem("vms_venue_filter_options") &&
  JSON.parse(localStorage.getItem("vms_venue_filter_options"));

const IS_REFRESH_DATE =
  localStorage.getItem("is_refresh") &&
  JSON.parse(localStorage.getItem("is_refresh"));

const IS_START_DATE =
  localStorage.getItem("start_date") && localStorage.getItem("start_date");

const IS_END_DATE =
  localStorage.getItem("end_date") && localStorage.getItem("end_date");

const INITIAL_DATE =
  (IS_REFRESH_DATE &&
    IS_START_DATE &&
    new Date(Number(localStorage.getItem("start_date")))) ||
  new Date(MIN_DATE_RANGE);

const END_DATE =
  (IS_REFRESH_DATE &&
    IS_END_DATE &&
    new Date(Number(localStorage.getItem("end_date")))) ||
  new Date();

export const VmsVenueDashboard = () => {
  // const auth = getAuth();
  const pageParams = useParams();
  const calendarRef = useRef(null);
  const isClickedOutside = useDetectOutsideClick(calendarRef);

  // const { handleStartLoading, handleStopLoading, onLogout } = useAuth();
  // const { userIdToken } = useAuthenticationStore();

  const {
    vmsVenuesData,
    setVmsVenuesData,
    layoutLg,
    setLayoutLg,
    lastUpdateTime,
    setLastUpdateTime,
  } = useVmsVenuesStore();

  const [dateRange, setDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [newDateRange, setNewDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [filters, setFilters] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(
    IS_FILTER_OPTIONS || []
  );
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState({ currentPage: 0, prevPage: 0 });
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("time_recorded");
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [maxHeight, setMaxHeight] = useState(null);

  const tableRef = useRef(null);
  const isFirstRender = useRef(true);
  const layoutRef = useRef(null);

  const analyticsParams = useMemo(() => {
    const params = {
      // user_id: localStorage.getItem("user_id"),
      // access_token: userIdToken,
      venue_id: pageParams.venueId,
      sort: orderBy,
      order: order === "desc" ? -1 : 1,
      skip: rowsPerPage * page.currentPage || 0,
      per_page: rowsPerPage,
      filters: selectedFilterOptions.map((s) => s.key),
      start_date: new Date(newDateRange[0])?.getTime(),
      end_date: new Date(newDateRange[1])?.getTime(),
    };
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // userIdToken,
    selectedFilterOptions,
    page?.currentPage,
    order,
    orderBy,
    newDateRange,
    rowsPerPage,
  ]);

  const dashboardData = useVMSVenueDashboardData(analyticsParams, true);

  const dashboard_data = dashboardData && dashboardData.data;
  const response_status = dashboardData && dashboardData.responseStatus;

  useEffect(() => {
    if (!vmsVenuesData) {
      // handleStartLoading();
      setIsLoadingFilters(true);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsUpdateData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!vmsVenuesData || isUpdateData) {
      const chk =
        analyticsParams?.access_token &&
        analyticsParams?.per_page > 0 &&
        isUpdateData;
      setIsFetch(chk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analyticsParams?.access_token,
    analyticsParams?.per_page,
    vmsVenuesData,
    isUpdateData,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setIsLoadingFilters(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsParams?.order, analyticsParams?.sort, analyticsParams?.skip]);

  useEffect(() => {
    if (dashboardData && dashboardData.responseStatus !== 200) {
      getLastUpdateTime();
      if (DATA_ERRORS.includes(dashboardData?.status))
        logout();
      // handleStopLoading();
      return;
    }
    if (dashboardData && dashboardData.responseStatus === 200) fetchAnalytics();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_data, response_status]);

  useEffect(() => {
    if (isClickedOutside && dateRange !== newDateRange)
      setDateRange(newDateRange);
    if (isClickedOutside) setIsShowCalendar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  useEffect(() => {
    async function calculateHeights() {
      const offsetTopGap =
        IS_MOBILE_SCREEN || IS_XXL_SCREEN ? 105 : IS_TAB_SCREEN ? 105 : 53;
      const tablePaddingTop = IS_MOBILE_SCREEN ? 8 : 14;
      const tableHeaderHeight = IS_MOBILE_SCREEN ? 50 : 51;
      const footerHeight = IS_MOBILE_SCREEN ? 74 : IS_TAB_SCREEN ? 94 : 74;
      const tableBottomGap = 40;
      const rowHeight = 47;
      const { offsetTop } = tableRef.current;

      const tableBodyHeight =
        window.innerHeight -
        offsetTop -
        offsetTopGap -
        tableHeaderHeight -
        tablePaddingTop -
        footerHeight -
        tableBottomGap;

      const rows = parseInt(tableBodyHeight / rowHeight);

      setTimeout(() => {
        if (layoutRef?.current?.elementRef?.current?.clientHeight) {
          const availableHeight =
            layoutRef?.current?.elementRef?.current?.clientHeight -
            offsetTop -
            footerHeight -
            22;

          setMaxHeight(availableHeight);
        }
      }, 3000);

      if (rows <= 0) {
        setRowsPerPage(1);
        handleUpdateLayout(100);
        return;
      }

      setRowsPerPage(rows);
      handleUpdateLayout(tableBodyHeight);
    }

    const observer = new MutationObserver(mutations => {
      for (let mutation of mutations) {
        if (mutation.type === 'childList' && layoutRef?.current?.elementRef?.current?.clientHeight) {
          calculateHeights();
          observer.disconnect();  // Stop observing once the element is found and heights are calculated
        }
      }
    });
  
    if (layoutRef?.current?.elementRef?.current) {
      calculateHeights();  // Element is already there, calculate heights directly
    } else {
      observer.observe(document.body, { childList: true, subtree: true });  // Start observing for changes in the DOM
    }
  
    return () => observer.disconnect(); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRef]);

  useEffect(() => {
    async function handleVisibilityChange() {
      if (!document.hidden) {
        const diff = getDifferenceOfUpdate();
        if (diff > ANALYTICS_REFETCH_INTERVAL) await dashboardData?.mutate();
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateTime]);

  useInterval(() => {
    const diff = getDifferenceOfUpdate();
    setIsUpdateData(diff > ANALYTICS_REFETCH_INTERVAL);
  }, ANALYTICS_REFETCH_INTERVAL);

  const fetchAnalytics = useCallback(() => {
    // handleStopLoading();
    setIsLoadingFilters(false);
    getLastUpdateTime();
    const filtersData = getFilters(dashboardData.data.filters);
    setFilters(filtersData);
    setVmsVenuesData(dashboardData.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const getFilters = (data) => {
    const structureFilters = Object.entries(data)
      .filter((d) => d[0] !== "all")
      .map(([key, values]) => ({
        key,
        name: key.replace(/_/i, " "),
        values: values.map((v) => ({ key: v, name: v.replace(/_/i, " ") })),
      }));
    return structureFilters;
  };

  const getLastUpdateTime = () => {
    const updateTime = getTimeOflastUpdate();
    setLastUpdateTime(updateTime);
  };

  const logout = async () => {
    // await onLogout();
    setIsLoadingFilters(false);
  };

  const getDifferenceOfUpdate = () => {
    return new Date().getTime() - new Date(lastUpdateTime).getTime();
  };

  const handleToggleCalendar = () => setIsShowCalendar(!isShowCalendar);

  const handleClickCalendarSearch = () => {
    setIsShowCalendar(false);
    setNewDateRange(dateRange);
    localStorage.setItem("start_date", new Date(dateRange[0]).getTime());
    localStorage.setItem("end_date", new Date(dateRange[1]).getTime());
    // handleStartLoading();
  };

  const onChangeDateRange = (value) => setDateRange(value);

  const showAttributeDetails = (values, index) => {
    let newArr = [];
    setOpenTooltip({ [`${values}-${index}`]: true });
    newArr = [`${values}-${index}`];
    setSelectedAttribute(newArr);
  };

  const handleCloseTooltip = (id) => setOpenTooltip({ [id]: false });

  const handleSelectFilter = (value) => setSelectedFilter(value);

  const handleSelectFilterOptions = (value) => {
    const index = selectedFilterOptions.findIndex((fo) => fo.key === value.key);
    if (index > -1) {
      const newArr = selectedFilterOptions;
      newArr.splice(index, 1);
      setSelectedFilterOptions([...newArr]);
      localStorage.setItem(
        "vms_venue_filter_options",
        JSON.stringify([...newArr])
      );
      return;
    }

    localStorage.setItem(
      "vms_venue_filter_options",
      JSON.stringify([...selectedFilterOptions, value])
    );

    setSelectedFilterOptions([...selectedFilterOptions, value]);
  };

  const handleBackButtonClick = () => {
    setPage({
      ...page,
      currentPage: page.currentPage - 1,
      prevPage: page.prevPage - 1,
    });
  };

  const handleNextButtonClick = () => {
    setPage({
      ...page,
      currentPage: page.currentPage + 1,
      prevPage: page.prevPage + 1,
    });
  };

  const handleRequestSort = (e, property) => {
    if (orderBy !== property) {
      setOrder("desc");
      setOrderBy(property);
      return;
    }
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleUpdateLayout = (tableHeight) => {
    const currLayout = layoutLg;

    const additionalHeight = IS_MOBILE_SCREEN
      ? 280
      : IS_TAB_SCREEN
      ? 245
      : IS_MD_SCREEN
      ? 280
      : 230;

    const layout = calculateLayoutHeight(
      currLayout,
      additionalHeight,
      tableHeight
    );

    setLayoutLg(layout);
  };

  const calculateLayoutHeight = (data, additionalHeight, tableHeight) => {
    const layout = data.map((l) => l);
    const a = (tableHeight + additionalHeight) / 15.75;
    layout[0] = { ...layout[0], h: Number((a / 10).toFixed(2)) };
    return layout;
  };

  return (
    <Box>
      <Box
        className="dashboard-wrapper"
        sx={{
          paddingBottom: { xs: "0px", lg: "10px" },
          paddingTop: { lg: 4.5 },
          paddingLeft: { xl: 5.3, lg: 4.5 },
        }}
      >
        

        <Container maxWidth="xl" sx={{ padding: "0px !important" }}>

          <Box className="last-update-time" > Last updated {lastUpdateTime}</Box>

          <Box className="static-layout-wrapper">
            <ResponsiveGridLayout
              className="layout"
              layouts={{
                lg: layoutLg,
                md: layoutLg,
                xs: layoutLg,
              }}
              breakpoints={GRID_BREAKPOINTS}
              cols={GRID_COLUMNS}
              isDraggable={false}
              isResizable={false}
              ref={layoutRef}
            >
              <Box key={VMS_VENUE_TABLE}>
                <VenueTable
                  tableRef={tableRef}
                  maxHeight={maxHeight}
                  calendarRef={calendarRef}
                  venues={vmsVenuesData?.results}
                  venueName={vmsVenuesData?.venue_name}
                  dateRange={dateRange}
                  filters={filters}
                  openTooltip={openTooltip}
                  selectedAttribute={selectedAttribute}
                  isLoading={isLoadingFilters}
                  showCalendar={isShowCalendar}
                  page={page.currentPage}
                  rowsPerPage={rowsPerPage}
                  dataLength={vmsVenuesData?.total_results}
                  showAttributeDetails={showAttributeDetails}
                  handleCloseTooltip={handleCloseTooltip}
                  selectedFilter={selectedFilter}
                  selectedFilterOptions={selectedFilterOptions}
                  onSelectFilters={handleSelectFilter}
                  onSelectFilterOptions={handleSelectFilterOptions}
                  handleBackButtonClick={handleBackButtonClick}
                  handleNextButtonClick={handleNextButtonClick}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  onToggleCalendar={handleToggleCalendar}
                  onClickSearch={handleClickCalendarSearch}
                  onChangeDateRange={onChangeDateRange}
                />
              </Box>
            </ResponsiveGridLayout>
          </Box>
        </Container>
      </Box>

      <Box className="message-alert">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={3000}
          open={open}
          onClose={() => {
            if (open) setOpen(false);
          }}
          message={open ? "Copied!!!" : ""}
        />
      </Box>
    </Box>
  );
};
