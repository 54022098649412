import React, { Fragment } from "react";
import { Box } from "@mui/material";

import { SearchInput } from "../SearchInput";
import { CustomButton } from "../Button";
import { Filter } from "./Filters";

export const TableSearchHeader = ({
  tableName,
  showFilters,
  reverseFilters,
  showSearch,
  filters,
  selectedFilter,
  selectedFilterOptions,
  onSelectFilters,
  onSelectFilterOptions,
  search,
  onChangeSearch,
  clearSearch,
  isDisabled,
  onClickOpenModal,
}) => {
  return (
    <Box className="table-head-d-flex table-input-field verify-head-wrapper">
      <h1 className="table-heading-name">{tableName}</h1>
      <Box className="table-head-d-flex d-flex-gap d-flex-verify">
        {showFilters && (
          <Filter
            reverseFilters={reverseFilters}
            filters={filters}
            selectedFilter={selectedFilter}
            selectedFilterOptions={selectedFilterOptions}
            onSelectFilters={onSelectFilters}
            onSelectFilterOptions={onSelectFilterOptions}
          />
        )}
        {showSearch && (
          <Fragment>
            <SearchInput
              value={search}
              onChange={onChangeSearch}
              clearSearch={clearSearch}
            />
            <Box className="table-head-d-flex d-flex-gap verify-btns-wrapper">
              <CustomButton
                variant="outlined"
                onClick={() => onClickOpenModal(true)}
                disabled={isDisabled}
              >
                Update
              </CustomButton>
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};
