import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { CustomButton } from "../Button";
import "./index.css";

export const DataMessage = ({ heading, description, onClick }) => {
  return (
    <TableRow>
      <TableCell colSpan={12} style={{ borderBottom: "none" }}>
        <div className="empty-data-msg-wrapper">
          <h3> {heading}</h3>
          <p> {description}</p>
          <CustomButton variant="outlined" onClick={onClick}>
            Refresh
          </CustomButton>
        </div>
      </TableCell>
    </TableRow>
  );
};
