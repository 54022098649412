import { combine } from "zustand/middleware";
import { create } from "zustand";
import { VMS_LAYOUT_LG } from "../../configs";

const getDefaultValues = () => ({
  venues: null,
  layoutLg: VMS_LAYOUT_LG,
  order: "asc",
  orderBy: "status",
  page: { currentPage: 0, prevPage: 0 },
  rowsPerPage: 0,
  maxHeight: null,
  lastUpdateTime: null,
});

export const useVmsStore = create(
  combine(getDefaultValues(), (set) => ({
    setVenues: (venuesData) => {
      set({ venues: venuesData });
    },
    setLayoutLg: (layout) => {
      set({ layoutLg: layout });
    },
    setOrder: (order) => {
      set({ order });
    },
    setOrderBy: (orderBy) => {
      set({ orderBy });
    },
    setPage: (page) => {
      set({ page });
    },
    setRowsPerPage: (rowsPerPage) => {
      set({ rowsPerPage });
    },
    setMaxHeight: (maxHeight) => {
      set({ maxHeight });
    },
    setLastUpdateTime: (lastUpdateTime) => {
      set({ lastUpdateTime });
    },
  }))
);
