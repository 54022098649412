import { combine } from "zustand/middleware";
import { create } from "zustand";
import { USER_LAYOUT_LG } from "../../configs";

const getDefaultValues = () => ({
  usersDashboardData: null,
  layoutLg: USER_LAYOUT_LG,
  maxHeight: null,
  lastUpdateTime: null,
});

export const useUserStore = create(
  combine(getDefaultValues(), (set) => ({
    setUsersDashboardData: (usersData) => {
      set({ usersDashboardData: usersData });
    },
    setLayoutLg: (layout) => {
      set({ layoutLg: layout });
    },
    setMaxHeight: (maxHeight) => {
      set({ maxHeight });
    },
    setLastUpdateTime: (lastUpdateTime) => {
      set({ lastUpdateTime });
    },
  }))
);
